import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Link as RouterLink } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { RiBillLine, RiHome5Line } from 'react-icons/ri';
import { TbMessageCircleBolt } from 'react-icons/tb';
import { BsChat } from 'react-icons/bs';
import { GoTasklist } from 'react-icons/go';
import { SiGooglemeet } from 'react-icons/si';


export default function MobileBottomNavigation() {
    const [value, setValue] = React.useState('Home');
    const ref = React.useRef(null);
    const listItem = [
        {
            text: "Home",
            icon: <RiHome5Line />,
            slug: "",
        },
        {
            text: "Projects",
            icon: <GoTasklist />,
            slug: "projects",

        },
        {
            text: "Booking",
            icon: <SiGooglemeet />,
            slug: "bookings",

        },
        {
            text: "Chat",
            icon: <BsChat />,
            slug: "chat",

        },
        {
            text: "Billing",
            icon: <RiBillLine />,
            slug: "billing",
        }
    ];
    return (
        <Box className='fixed-footer' sx={{ pb: 7 }} ref={ref}>
            <CssBaseline />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, py: "0.5rem" }} elevation={3}>
                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    {
                        listItem.map((item, index) => (
                            <BottomNavigationAction
                                LinkComponent={RouterLink}
                                to={`/${item.slug}`}
                                key={index}
                                label={item.text}
                                value={item.text}
                                icon={item.icon}
                            />
                        ))
                    }
                </BottomNavigation>
            </Paper>
        </Box>
    );
}

