import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Grid,
  Typography,
  InputAdornment,
  Box,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import CreatableSelect from "react-select/creatable";
import ImageUpload from "../components/ImageUpload";
import { API_URL, BACKEND_URL } from "../zustand/const";
import { makeRequest } from "../util/AxiosInstance";
import { Block, Confirm, Notify } from "notiflix";
import DisplayAlerts from "../shared/DisplayAlerts";
import { LoadingButton } from "@mui/lab";
import BackButton from "../components/BackButton";
import { handleError } from "../util/functions";

const PortfolioEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [industry, setIndustry] = useState(null)
  const [category, setCategory] = useState(null)

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    duration_start: "",
    duration_end: "",
    price_start: "",
    price_end: "",
    category: null,
    industry: null,
    image_order: []
  });

  const [clearImageUpload, setClearImageUpload] = useState(false);
  const [files, setFiles] = useState([]);

  const setImageOrder = () => {
    let imageOrder = files
      .filter(image => image.uploadData && image.uploadData.id)
      .map(image => image.uploadData.id);
    setFormData({ ...formData, image_order: imageOrder });
  }
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (name) => (value) => {
    if (name === 'industry') {
      setIndustry(value)
    }
    if (name === 'category') {
      setCategory(value)
    }
    setFormData({ ...formData, [name]: value.label });
  };
  const [categories, setCategories] = useState([]);
  const [industries, setIndustries] = useState([]);

  const handleCreateOption = (inputValue, type) => {
    const newOption = { label: inputValue, value: inputValue };

    let data = {
      type: type,
      value: inputValue
    }
    if (type === 'category') {
      setCategories([...categories, newOption])
    }
    if (type === 'industry') {
      setIndustries([...industries, newOption])
    }
    setFormData({ ...formData, [type]: newOption.value });

    makeRequest('POST', `${API_URL}/portfolio/properties`, data)
      .then(response => {
        console.log(`New ${type} created:`, response.data)
      })
      .catch(error => console.error(`Error creating ${type}:`, error));
  };

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.description) newErrors.description = "Description is required";
    if (!formData.duration_start) newErrors.duration_start = "Start date is required";
    if (!formData.duration_end) newErrors.duration_end = "End date is required";
    if (new Date(formData.duration_end) <= new Date(formData.duration_start)) {
      newErrors.duration_end = "End date must be after start date";
    }
    if (!formData.price_start) newErrors.price_start = "Start price is required";
    if (!formData.price_end) newErrors.price_end = "End price is required";
    if (parseFloat(formData.price_end) < parseFloat(formData.price_start)) {
      newErrors.price_end = "End price must be greater than or equal to start price";
    }
    if (!formData.category) newErrors.category = "Category is required";
    if (!formData.industry) newErrors.industry = "Industry is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const loadData = async () => {
    try {
      setIsLoading(true)
      Block.hourglass('.portfolio-editor')

      await makeRequest('GET', `${API_URL}/portfolio/${id}`)
        .then(async response => {
          let res = response.data.data;
          let image_order = res.image_order;
          let newFormData = {
            name: res.name,
            description: res.description,
            duration_start: res.duration_start,
            duration_end: res.duration_end,
            price_start: res.price_start,
            price_end: res.price_end,
            category: null,
            industry: null,
            image_order: res.image_order
          }
          newFormData.duration_start = new Date(res.duration_start).toISOString().split('T')[0]
          newFormData.duration_end = new Date(res.duration_end).toISOString().split('T')[0]
          // update category
          let category = categories.find(cat => cat.label.toLowerCase() === res.category.toLowerCase())
          // update industry
          let industry = industries.find(ind => ind.label.toLowerCase() === res.industry.toLowerCase())
          newFormData.category = category?.label
          newFormData.industry = industry?.label
          setCategory(category)
          setIndustry(industry)
          //
          setFormData(newFormData)
          if (image_order) {
            const filesResponse = await makeRequest('GET', `${API_URL}/file?id=${image_order.join(',')}`);
            let allFiles = filesResponse.data.data
            let usableFile = allFiles.map((file) => {
              return {
                id: file.id,
                preview: `${BACKEND_URL}${file.file_path}`,
                uploadData: file,
                uploading: false
              }
            })
            setFiles(usableFile);
          }
        })
    } catch (error) {
      let err = handleError(error)
      setErrors(err)
      Notify.failure(err)
    } finally {
      setIsLoading(false)
      Block.remove('.portfolio-editor')
    }
  }

  const clearForm = () => {
    setFormData({
      name: "",
      description: "",
      duration_start: "",
      duration_end: "",
      price_start: "",
      price_end: "",
    });
    setFiles([])
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsLoading(true)
    Block.hourglass('.portfolio-editor')
    try {
      await makeRequest(
        id ? 'PUT' : 'POST',
        `${API_URL}/portfolio${id ? `/${id}` : ''}`,
        formData
      );

      Notify.success(id ? "Portfolio updated successfully" : "Portfolio created successfully");
      Confirm.show(
        'Project Created',
        'What would you like to do next?',
        'Create New',
        'View all projects',
        () => {
          clearForm()
        },
        () => {
          navigate('/portfolio');
        }
      );
      // clear form

    } catch (error) {
      let err = handleError(error)
      setErrors(err)
      Notify.failure(err)
    } finally {
      setIsLoading(false)
      Block.remove('.portfolio-editor')
    }
  };


  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await Promise.all([
          makeRequest('GET', `${API_URL}/portfolio/properties/type/category`),
          makeRequest('GET', `${API_URL}/portfolio/properties/type/industry`)
        ]);
        const [categoriesResponse, industriesResponse] = response;
        let categories = categoriesResponse.data.data
        let industries = industriesResponse.data.data
        setCategories(categories.map(cat => ({ label: cat.value, value: cat.id })));
        setIndustries(industries.map(ind => ({ label: ind.value, value: ind.id })));
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    if (id) {
      loadData()
    }
  }, [id])


  return (
    <Box className='portfolio-editor'>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: {
          xs: '1rem',
          sm: '2rem',
        }
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackButton />
          <Typography variant="h4">
            {id ? "Edit Portfolio Project" : "Create Portfolio"}
          </Typography>
        </div>
        <LoadingButton onClick={handleSubmit} variant="contained" color="primary" loading={isLoading}>Submit</LoadingButton>
      </Box>
      <DisplayAlerts apiErrors={errors} />
      <form action="#" onSubmit={handleSubmit} disabled={isLoading}>
        <Grid className="project-form-container" container spacing={3}>
          <Grid item xs={12}>
            <div className="form-field">
              <Typography variant="body1">Name</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Enter the project name
              </Typography>
            </div>
            <TextField
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="form-field">
              <Typography variant="body1">Description</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Provide a detailed description of the project
              </Typography>
            </div>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <div className="form-field">
              <Typography variant="body1">Commencement Date</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Select the date the project started
              </Typography>
            </div>
            <TextField
              fullWidth
              type="date"
              name="duration_start"
              value={formData.duration_start}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <div className="form-field">
              <Typography variant="body1">Completion Date</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Select the date the project was completed
              </Typography>
            </div>
            <TextField
              fullWidth
              type="date"
              name="duration_end"
              value={formData.duration_end}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6} >
            <div className="form-field">
              <Typography variant="body1">Price Start</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Enter the starting price
              </Typography>
            </div>
            <TextField
              fullWidth
              type="number"
              name="price_start"
              value={formData.price_start}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <div className="form-field">
              <Typography variant="body1">Price End</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Enter the ending price
              </Typography>
            </div>
            <TextField
              fullWidth
              type="number"
              name="price_end"
              value={formData.price_end}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <div className="form-field">
              <Typography variant="body1">Category</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Select an existing category or create a new one
              </Typography>
            </div>
            <CreatableSelect
              isClearable
              onChange={handleSelectChange("category")}
              value={category}
              options={categories}
              placeholder="Select or create a category"
              onCreateOption={(inputValue) => handleCreateOption(inputValue, "category")}
            />
          </Grid>
          <Grid item xs={6}>
            <div className="form-field">
              <Typography variant="body1">Industry</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Select an existing industry or create a new one
              </Typography>
            </div>
            <CreatableSelect
              isClearable
              onChange={handleSelectChange("industry")}
              value={industry}
              options={industries}
              placeholder="Select or create an industry"
              onCreateOption={(inputValue) => handleCreateOption(inputValue, "industry")}
            />
          </Grid>
          <Grid item xs={12}>
            <ImageUpload files={files} setFiles={setFiles} clearForm={clearImageUpload} setImageOrder={setImageOrder} />
          </Grid>
        </Grid>
      </form>
      {/* <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar> */}
    </Box>
  );
};

export default PortfolioEditor