import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatDate, formatDateTime } from '../util/functions';

const ProjectSummary = ({ title, description, amount, currency, startAt, endAt }) => {
    return (
        <>
            <Box
                sx={{
                    textTransform: 'capitalize',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
                className='booking-summary card-box'
            >
                <Typography variant="h6" gutterBottom>Project Summary</Typography>
                <Typography variant="body1"><span>Title:</span> <p>{title}</p></Typography>
                <Typography variant="body1"><span>Description:</span> <p>{description}</p></Typography>
                <Typography variant="body1"><span>Amount:</span> <p>{amount}</p></Typography>
                <Typography variant="body1"><span>Start Date:</span> <p>{formatDateTime(startAt) ?? ''}</p></Typography>
                <Typography variant="body1"><span>End Date:</span> <p>{formatDateTime(endAt) ?? ''}</p></Typography>
            </Box>
        </>
    );
};

export default ProjectSummary;