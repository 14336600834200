import React, { useEffect, useState } from 'react';
import { Box, IconButton, styled, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useChartStore } from '../zustand';
import { Notify } from 'notiflix';
import dayjs from 'dayjs';
import { VscListFilter } from 'react-icons/vsc';


function DateRangeSelector() {
    const { startDate, endDate, setStartDate, setEndDate } = useChartStore();
    // Set default values for startDate and endDate
    const [sDate, setSDate] = useState(dayjs(new Date(new Date().setDate(new Date().getDate() - 6))));
    const [eDate, setEDate] = useState(dayjs(new Date()));

    useEffect(() => {
        const defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 6));
        const defaultEndDate = new Date();
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setSDate(dayjs(defaultStartDate));
        setEDate(dayjs(defaultEndDate));
    }, []);


    const handleStartDateChange = (date) => {
        setStartDate(date);
        setSDate(date);
    };

    const handleEndDateChange = (date) => {
        if (date < startDate) {
            Notify.warning('End date cannot be before start date');
            return;
        }
        setEndDate(date);
        setEDate(date);
    };
    const [useFilter, setUseFilter] = useState(false);


    return <>


        {useFilter ?
            <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box className="date-picker-wrapper" gap={'1rem'} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <DatePicker
                            value={sDate}
                            placeholder="Start Date"
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField
                                className='date-picker-1'
                                variant="standard"
                                size='small'
                                {...params} />}
                        />
                        <DatePicker
                            defaultValue={new Date()}
                            value={eDate}
                            onChange={handleEndDateChange}
                            className='date-picker'
                            renderInput={(params) => <TextField
                                variant="standard"
                                className='date-picker-2'
                                size='small'
                                {...params} />}
                        />
                    </Box>
                </LocalizationProvider>
            </>
            :
            <IconButton onClick={() => setUseFilter(true)}><VscListFilter /></IconButton>
        }
    </>

}

export default DateRangeSelector;