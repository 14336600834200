import * as React from "react";
import DashboardMenu from "../components/DashboardMenu";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import {
  CssBaseline,
  Box,
  Toolbar,
  AppBar,
  Divider,
  Drawer,
  useMediaQuery,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import DashboardMenuFooter from "../components/DashboardMenuFooter";
import { userStore } from "../zustand";
import MobileBottomNavigation from "../components/BottomNavigation";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../zustand/const";
import GreetingComponent from "../components/GreetingComponent";
import { Logo } from "../components/LogoTab";
import ProfileTab from "../components/ProfileTab";
import '../style/dashboard.css'
import AuthWrapper from "./AuthWrapper";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";



function Dashboard({ window, children }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
      },
    },
    typography: {
      fontFamily: "Inter, sans-serif",
    },
  });
  const [tag, setTag] = React.useState("")
  const queryClient = new QueryClient()
  const [drawerWidth, setDrawerWidth] = React.useState(240); // [1
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const { user } = userStore()
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  const { pathname } = useLocation()

  React.useEffect(() => {
    const pages = ["/register", "/login", "/forgot-password", "/verify-email"]

    if (!pages.includes(pathname)) {
      setTag("main-page")
    }
  }, [pathname])

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (isDesktop) {
      setDrawerWidth(240);
    } else {
      setDrawerWidth(90);
    }
  }, [isDesktop]);

  const container =
    window !== undefined ? () => window().document.body : undefined;
    
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthWrapper>
        <QueryClientProvider client={queryClient}>
          <Box className={`dashboard ${pathname === "/chat" ? "chat-page" : ""}`} sx={{ display: "flex" }}>
            <AppBar
              position="fixed"
              className="appbar"
              sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
                flexDirection: "row",
                height: "90px",
                padding: "0 0.5rem"
              }}
            >
              <Toolbar className="topbar" sx={{ display: "flex", width: "100%" }}>
                {isMobile && <Logo showText={true} />}
                {!isMobile && <GreetingComponent />}
                <ProfileTab />
              </Toolbar>
            </AppBar>
            <Box
              component="nav"
              className="dashboard-nav"
              sx={{
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
                backgroundColor: "#fff"
              }}
              aria-label="mailbox folders"
            >
              <Drawer
                container={container}
                variant="temporary"
                className="sidebar-mobile"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                  backgroundColor: "#fff",
                }}
              >
                <Logo key={isDesktop} isDesktop={isDesktop} />
                <Divider />
                <DashboardMenu />
              </Drawer>
              <Drawer
                variant="permanent"
                className="sidebar-web"
                sx={{
                  display: { xs: "none", sm: "flex" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                  backgroundColor: "#fff",
                }}
                open
              >
                <Logo />
                <DashboardMenu />
                <DashboardMenuFooter />
              </Drawer>
            </Box>
            <Box
              component="main"
              className="dashboard-main"
              sx={{
                flexGrow: 1,
                p: "2rem",
                pt: "90px",
                width: { sm: `calc(100% - ${drawerWidth}px)` },
              }}
            >
              <Outlet />
            </Box>
            {isMobile && <MobileBottomNavigation />}
          </Box>
        </QueryClientProvider>
      </AuthWrapper>
    </ThemeProvider>
  );
}

export default Dashboard;
