import axios from "axios";
import { API_URL, IndexDbStoreName, IndexedDbName } from "../zustand/const";
import { IndexedDBUtility } from "./IndexedDb";
import { isEmpty } from "underscore";

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let db = new IndexedDBUtility(IndexedDbName, IndexDbStoreName);
    await db.openDatabase();
    const token = await db.readFromDatabase('jwt');
    if (!isEmpty(token)) {
      const { jwt } = token;
      config.headers.Authorization = `Bearer ${jwt}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const makeRequest = (method, url, data = null, config = {}) => {
  const { headers = {}, onDownloadProgress, ...restConfig } = config;

  const requestConfig = {
    method,
    url,
    ...restConfig,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  };

  if (data) {
    requestConfig.data = data;
  }

  if (onDownloadProgress && typeof onDownloadProgress === 'function') {
    requestConfig.onDownloadProgress = onDownloadProgress;
  }

  return axiosInstance(requestConfig);
};

export default axiosInstance;