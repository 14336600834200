import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { RiHome5Line } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { HiOutlineUser } from "react-icons/hi2";
import { RiBillLine } from "react-icons/ri";
import { SiGooglemeet } from "react-icons/si";
import { BsChat } from "react-icons/bs";
import { GoTasklist } from "react-icons/go";
import { BsFolderCheck } from "react-icons/bs";
export function DashboardMenuContainer({ listItem, classList }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { pathname } = useLocation()

  return (
    <List className={`dashboard-menu-list ${classList}`}>
      {listItem.map((item, index) => (
        <ListItem
          key={index}
          disablePadding
          component={Link}
          {...(item.action ? { onClick: item.action } : { to: `/${item.slug}` })}
          className="dashboard-menu-item"
        >
          <ListItemButton
            selected={item.slug === pathname.slice(1)}
            className="dashboard-menu-btn"

          >
            <ListItemIcon sx={{
              fontSize: "1.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}>{item.icon}</ListItemIcon>
            {isDesktop && <ListItemText primary={item.text} />}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

function DashboardMenu() {
  const listItem1 = [
    {
      text: "Home",
      icon: <RiHome5Line />,
      slug: "",
    },
    {
      text: "Projects",
      icon: <GoTasklist />,
      slug: "projects",
    },
    {
      text: "Bookings",
      icon: <SiGooglemeet />,
      slug: "bookings",
    },
    {
      text: "Chat",
      icon: <BsChat />,
      slug: "chat",
    },
    {
      text: "Portfolio",
      icon: <BsFolderCheck />,
      slug: "portfolio",
    },

    {
      text: "Billing",
      icon: <RiBillLine />,
      slug: "billing",
    },
    {
      text: "Account",
      icon: <HiOutlineUser />,
      slug: "account",
    },


  ];
  return (
    <DashboardMenuContainer listItem={listItem1} />
  );
}
export default DashboardMenu;
