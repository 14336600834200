import { create } from "zustand";
import { persist } from "zustand/middleware";
import { API_URL } from "./const";
import { makeRequest } from "../util/AxiosInstance";
import { Notify } from "notiflix";
import timezonesFallback from '../assets/timezones.json';
import { isArray } from "underscore";

const useProjectStore = create((set) => ({
  projects: [],
  ongoing: [],
  pending: [],
  completed: [],
  status: [],
  loadMore: async (type = 1, page = 1) => {
    /**
     * Type 1: Ongoing projects
     * Type 2: Pending projects
     * Type 3: Completed projects
     */
    try {
      if (typeof type !== 'number') {
        type = +type ?? 1
      }
      makeRequest(`GET`, `${API_URL}/projects?status=${type}&page=${page}`)
        .then(async (response) => {
          if (!response.status) {
            throw new Error("Failed to fetch projects");
          }
          const data = response.data.data.data ?? [];
          let ongoing = useProjectStore.getState().ongoing;
          let pending = useProjectStore.getState().pending;
          let completed = useProjectStore.getState().completed;
          let projects = useProjectStore.getState().projects;

          switch (type) {
            case 1:
              set({ ongoing: [...ongoing, ...data] });
              break;
            case 2:
              set({ pending: [...pending, ...data] });
              break;
            case 3:
              set({ completed: [...completed, ...data] });
              break;
            default:
              set({
                projects: [...projects, ...data],
              });
          }
        }).catch((error) => {
          console.error("Error fetching projects:", error);
        })
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  },
  fetchProjects: (type = 1, page = 1) => {
    try {
      if (typeof type !== 'number') {
        type = +type ?? 1
      }
      makeRequest(`GET`, `${API_URL}/projects?status=${type}&page=${page}`)
        .then(async (response) => {
          if (!response.status) {
            throw new Error("Failed to fetch projects");
          }
          const data = response.data.data.data ?? [];
          switch (type) {
            case 1:
              set({ ongoing: [...data] });
              break;
            case 2:
              set({ pending: [...data] });
              break;
            case 3:
              set({ completed: [...data] });
              break;
            default:
              set({
                projects: [...data],
              });
          }
        }).catch((error) => {
          console.error("Error fetching projects:", error);
        })
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  },
  // Function to add a new project
  addProject: (newProject) => {
    set((state) => ({
      projects: [...state.projects, newProject],
    }));
  },
  removeProject: (projectId) => {
    set((state) => ({
      projects: state.projects.filter((project) => project.id !== projectId),
    }));
  },
  loadStatus: () => {
    makeRequest('GET', `${API_URL}/get-project-all-status`)
      .then((response) => {
        let data = response.data.data
        console.log(data);
        set({ status: data });
      }).catch((error) => {
        console.error("Error fetching projects:", error);
      })
  }
}));


const userStore = create(persist((set) => ({
  user: null,
  token: "",
  isLoading: false,
  isLoggedIn: () => {
    return !!localStorage.getItem("token");
  },
  setUser: (user) => {
    set({ user });
  },
  setToken: (token) => {
    set({ token });
  },
  getUser: async () => {
    set({ isLoading: true });
    makeRequest('GET', `${API_URL}/user`).then((response) => {
      console.log(response);
      set({ user: response.data.data });
    }).catch((error) => {
      Notify.failure("Failed to fetch user data");
      console.log(error);
    }).finally(() => {
      set({ isLoading: false });
    });
  },
  checkLogin: async () => {
    if (localStorage.getItem("token")) {
      console.log("Token exists in local storage");
      userStore.getState().getUser();
    }
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      set({ token: "", user: null });
      resolve(true);
    });
  }
}),
  {
    name: "user-storage",
  }));

const useBookingStore = create((set) => ({
  bookings: [],
  unapproved: [],
  changeOfTime: [],
  history: [],
  currentPage: 1,
  hasMore: true,
  isLoading: false,
  itemsPerPage: 10,
  setPage: (page) => set({ currentPage: page }),
  sortedBookings: { expired: [], upcoming: [], unapproved: [] },
  fetchBookings: async (status, page) => {
    try {
      set({ isLoading: true });
      makeRequest('GET', `/bookings?status=1&page=${page}`).then((response) => {
        const data = response.data.data;
        switch (status) {
          case 0:
            set((state) => ({
              bookings: [...state.bookings, ...data],
              hasMore: response.data.next_page_url !== null,
            }));
            break;

          default:
            break;
        }

      }).catch((error) => {
        Notify.failure("Failed to fetch bookings");
        console.error("Failed to fetch bookings", error);
      }).finally(() => {
        set({ isLoading: false });
      });
    } catch (error) {
      set({ isLoading: false });
      Notify.failure("Failed to fetch bookings");
      console.error("Failed to fetch bookings", error);
    }
  },
  loadMore: () => set((state) => ({ currentPage: state.currentPage + 1 })),
  setCurrentPage: (page) => set({ currentPage: page }),
}));

export const AppMeta = create((set) => ({
  roles: [
    {
      "id": 1,
      "name": "user"
    },
    {
      "id": 2,
      "name": "admin"
    }
  ],
  bookingStatus: {
    "PENDING_ADMIN_APPROVAL": "0",
    "APPROVED_BY_ADMIN": "1",
    "CLOSED_BY_ADMIN": "2",
    "CANCELLED_BY_ADMIN": "3",
    "REQUESTED_CHANGE_OF_TIME_BY_ADMIN": "4",
    "REQUESTED_CHANGE_OF_TIME_BY_USER": "5",
    "APPROVED_BY_USER": "6",
    "CANCELLED_BY_USER": "7"
  },
  projectStatus: {
    "PROJECT_CREATED_BY_ADMIN": "1",
    "PROJECT_CANCELLED_BY_ADMIN": "2",
    "PROJECT_CLOSED_BY_ADMIN": "3"
  },
  chatTypes: {
    "TEXT": "1",
    "FILE": "2"
  },
  projectOrderStatus: {
    "PENDING": "0",
    "SUCCESS": "1",
    "FAILED": "2"
  },
  fileTypes: [
    {
      "id": 1,
      "name": "pdf",
      "status": "1"
    },
    {
      "id": 2,
      "name": "video",
      "status": "1"
    },
    {
      "id": 3,
      "name": "image",
      "status": "1"
    },
    {
      "id": 4,
      "name": "file",
      "status": "1"
    }
  ],
  setRoles: (roles) => {
    set({ roles: [...roles] })
  }

}))

// Define the initial state
const initialState = [
  { day: 'Monday', start: '09:00', end: '17:00' },
  { day: 'Tuesday', start: '09:00', end: '17:00' },
  { day: 'Wednesday', start: '09:00', end: '17:00' },
  { day: 'Thursday', start: '09:00', end: '17:00' },
  { day: 'Friday', start: '09:00', end: '17:00' },
  { day: 'Saturday', start: '10:00', end: '14:00' },
  { day: 'Sunday', start: '10:00', end: '14:00' },
];

// Create the Zustand store
const useAvailabilityStore = create((set) => ({
  availability: initialState,
  updateAvailability: (day, newStart, newEnd) =>
    set((state) => ({
      availability: state.availability.map((d) =>
        d.day === day ? { ...d, start: newStart, end: newEnd } : d
      ),
    })),
}));

const useTimeZoneStore = create((set) => ({
  timezones: [],
  getTzById: (id) => {
    let timezones = useTimeZoneStore.getState().timezones;
    return timezones.find(tz => tz.id === id);
  },
  fetchTimezones: async () => {
    makeRequest('GET', `${API_URL}/timezones`).then((response) => {
      set({ timezones: response.data.data });
    }).catch((error) => {
      console.error("Failed to fetch timezones", error);
      set({ timezones: timezonesFallback });
    })
  },
}));

export const normalizeAndSortMessages = (data) => {
  const messages = data.map((item) => ({
    id: item.id,
    projectId: item.project_id,
    content: item.project_chat_contentable.content,
    createdAt: new Date(item.created_at),
    updatedAt: new Date(item.updated_at),
    type: item.project_chat_contentable_type,
  }));

  return messages.sort((a, b) => b.createdAt - a.createdAt);
};

export const useChatStore = create(persist((set, get) => ({
  messages: [],
  dms: [],
  loadMessages: (data) => {
    // const normalizedMessages = normalizeAndSortMessages(data);
    // set({ messages: normalizedMessages });
  },
  loadDms: () => {
    let user_id = userStore.getState().user.id
    if (!user_id) {
      return;
    }
    makeRequest('GET', `${API_URL}/projects/chat?user_id=${user_id}`).then((response) => {
      let data = response.data.data.data
      // console.log(data);
      if (isArray(data) && data.length > 0) {
        set({ dms: data });
      }
    }).catch((error) => {
      console.error("Failed to fetch messages", error);
    })
  },
  setMessages: (data) => {
    set({ messages: [...data] });
  },
  clearMessages: () => {
    set({ messages: [] });
  },
  sendMessage: (data) => {
    let messages = useChatStore.getState().messages;
    let user = userStore.getState().user
    let apiData = data
    let projectId = data.project_id;
    data.senderId = user.id
    let pjData = messages.find(m => m.projectId === projectId);
    if (pjData) {
      pjData.data.push(data);
    } else {
      messages.push({ projectId, data: [data] });
    }
    set({ messages });
    makeRequest('POST', '/create-project-chat', apiData).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    })
  },
  loadMore: (newData) => {
    const newMessages = normalizeAndSortMessages(newData);
    set((state) => ({
      messages: [...state.messages, ...newMessages].sort((a, b) => b.createdAt - a.createdAt),
    }));
  },
  createDm: async (projectId) => {
    return new Promise((resolve, reject) => {
      let messages = useChatStore.getState().messages;
      messages.push({ projectId, data: [] });
      set({ messages });
      resolve(messages)
    })

  }
})
  , {
    name: "chat-storage",
  }
));

export const useImageUploadStore = create((set, get) => ({
  uploading: false,
  filesToUpload: [],
  files: [],
  modalOpen: false,
  currentImageIndex: 0,

  setUploading: (uploading) => set({ uploading }),
  setFilesToUpload: (filesToUpload) => set({ filesToUpload }),
  setFiles: (files) => set({ files }),
  setModalOpen: (modalOpen) => set({ modalOpen }),
  setCurrentImageIndex: (currentImageIndex) => set({ currentImageIndex }),

  addFiles: (newFiles) => set(state => ({
    files: [...state.files, ...newFiles],
    filesToUpload: [...state.filesToUpload, ...newFiles]
  })),

  removeFile: (fileId) => set(state => ({
    files: state.files.filter(f => f.id !== fileId),
    filesToUpload: state.filesToUpload.filter(f => f.id !== fileId)
  })),

  updateFile: (fileId, updates) => set(state => ({
    files: state.files.map(f => f.id === fileId ? { ...f, ...updates } : f),
    filesToUpload: state.filesToUpload.map(f => f.id === fileId ? { ...f, ...updates } : f)
  })),

  clearFiles: () => set({ files: [], filesToUpload: [], currentImageIndex: 0 }),

  nextImage: () => set(state => ({
    currentImageIndex: (state.currentImageIndex + 1) % state.files.length
  })),

  prevImage: () => set(state => ({
    currentImageIndex: (state.currentImageIndex - 1 + state.files.length) % state.files.length
  })),
}));
export const analyticsStore = create((set) => ({
  data: {},
  setData: (data) => set({ data }),
  getAnalytics: async () => {
    makeRequest('GET', `${API_URL}/get-total-analytics`).then((response) => {
      set({ data: response.data.data });
    }).catch((error) => {
      Notify.failure("Failed to fetch analytics");
      console.error("Failed to fetch analytics", error);
    })
  }
}));

export const useBookingModal = create((set) => ({
  open: false,
  booking: {},
  id: null,
  setOpen: (open) => {
    set({ open })
  },
  setBooking: (booking) => set({ booking }),
  setId: (id) => set({ id }),
}))

const useChartStore = create((set) => ({
  projects: [],
  setProjects: (projects) => set({ projects }),
  chartData: [],
  setChartData: (chartData) => set({ chartData: [...chartData] }),
  startDate: null,
  endDate: null,
  setStartDate: (startDate) => set({ startDate }),
  setEndDate: (endDate) => set({ endDate }),
}))

const useBookingCardStore = create((set) => ({
  bookings: [],
  isLoading: false,
  setBookings: (bookings) => set({ bookings }),
  setIsLoading: (isLoading) => set({ isLoading }),
}))

const useProjectCardStore = create((set) => ({
  projects: [],
  isLoading: false,
  fetchProjects: async () => {
    try {
      set({ isLoading: true });
      makeRequest('GET', `${API_URL}/projects?perPage=${5}&status=2`).then((response) => {
        console.log(response.data.data.data);
        set({ projects: response.data.data.data ?? [] });
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        set({ isLoading: false });
      })
    } catch (error) {
      console.log(error);
    }
  },
  setProjects: (projects) => set({ projects }),
  setIsLoading: (isLoading) => set({ isLoading }),
}))

const navigationContext = create(persist((set) => ({
  ongoing: {
    page: 1,
  },
  completed: {
    page: 1,
  },
  pending: {
    page: 1,
  },
  setOngoingPage: (page) => set({ ongoing: { page } }),
  setCompletedPage: (page) => set({ completed: { page } }),
  setPendingPage: (page) => set({ pending: { page } }),
}), {
  name: "navigation-storage",
}))


const usePortfolioStore = create((set) => ({
  portfolios: [],
  setPortfolios: (portfolios) => set({ portfolios }),
  addPortfolio: (portfolio) => set((state) => ({ portfolios: [...state.portfolios, ...portfolio] })),
  currentPage: 1,
  totalItems: 0,
  hasMore: true,
  setCurrentPage: (page) => set({ currentPage: page }),
  increasePage: () => set((state) => ({ currentPage: state.currentPage + 1 })),
  setTotalItems: (totalItems) => set({ totalItems }),
  setHasMore: (hasMore) => set({ hasMore }),
}))

export { useProjectStore, useBookingStore, userStore, useAvailabilityStore, useTimeZoneStore, useChartStore, useBookingCardStore, useProjectCardStore, navigationContext, usePortfolioStore };

