import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeRequest } from '../util/AxiosInstance';
import { API_URL } from '../zustand/const';
import BackButton from '../components/BackButton';
import { Notify } from 'notiflix';
import { LoadingButton } from '@mui/lab';
import DisplayAlerts from '../shared/DisplayAlerts';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectSummary from '../components/ProjectSummary';
import SearchUser from '../components/SearchUser';
import { isEmpty } from 'underscore';
import { formatDateForApi, handleError } from '../util/functions';
import SuccessMsg from '../components/SuccessMsg';


const ProjectEditor = ({ onSuccess }) => {
  let { id: editID } = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState(null);
  const [step, setStep] = useState(1);
  const [successMsg, setSuccessMsg] = useState(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [selectedUser, setSelectedUser] = useState(null);

  const clearAll = () => {
    setTitle('');
    setDescription('');
    setAmount('');
    setStartAt('');
    setEndAt('');
    setSelectedUser(null);
    setApiErrors(null);
    setSuccessMsg(null);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // add validation action
    if (!editID && (isEmpty(selectedUser) || !selectedUser.id)) {
      Notify.failure('Please select a user');
      return;
    }
    if (isEmpty(title) || isEmpty(description) || isEmpty(amount) || isEmpty(startAt) || isEmpty(endAt)) {
      Notify.failure('Please fill all fields');
      return;
    }
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('amount', +amount);
    formData.append('start_at', formatDateForApi(startAt));
    formData.append('end_at', formatDateForApi(endAt));
    if (!editID) {
      formData.append('user_id', +selectedUser.id);
    }
    setIsLoading(true);
    try {
      makeRequest('POST', editID ? `${API_URL}/update-project/${editID}` : `${API_URL}/create-project`, formData).then((response) => {
        if (response.data.status) {
          let text = `Project ${editID ? 'Updated' : "Created"} Successfully`
          Notify.success(text);
          setSuccessMsg(text);
          setStep(4);
          clearAll();
          if (onSuccess) {
            onSuccess(response.data.data);
          }
        }
        console.log(response.data);
      }).catch((error) => {
        Notify.failure('Failed to create project: ' + error.message);
        console.error('Error:', error);
        setApiErrors(error.response.data.message ?? error.message);
      }).finally(() => {
        setIsLoading(false);
      })
    } catch (error) {
      Notify.failure('Failed to create project: ' + error.message);
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (editID) {
      makeRequest('GET', `${API_URL}/get-project/${editID}`).then((response) => {
        const data = response.data.data;
        setTitle(data.title);
        setDescription(data.description);
        setAmount(data.project_detail.amount);
        setStartAt(data.project_detail.start_at);
        setEndAt(data.project_detail.end_at);
        return data
      })
        .then((data) => {
          console.log(data);

          // makeRequest('GET', `${API_URL}/get-user?user_id=${data.user_id}`).then((response) => {
          //   const data = response.data.data;
          //   setSelectedUser(data);
          // }).catch((error) => {
          //   let msg = handleError(error)
          //   Notify.failure(msg)
          //   console.error('Error:', error);
          // })
        })
        .catch((error) => {
          Notify.failure('Failed to fetch project data: ' + error.message);
          console.error('Error:', error);
        });
    }
  }, [editID]);

  return (
    <>
      <Box sx={{
        marginBottom: {
          xs: 3.5,
          sm: 5,
        }
      }}
        display={'flex'} alignItems={'center'}>
        <BackButton />
        <Typography fontWeight={'600'} variant="h5" mb={0}>{editID ? 'Update Project' : 'Create Project'}</Typography>
      </Box>
      <DisplayAlerts success={successMsg} apiErrors={apiErrors} />
      {step !== 4 && (
        <Grid container spacing={2}>
          {((isDesktop || step === 1) && step !== 4) && (<Grid item xs={12} lg={7} xl={6}>
            <Box component="form" className='project-form' onSubmit={handleSubmit}
              sx={{
                display: 'flex', flexDirection: 'column', gap: 2, backgroundColor: 'white',
                padding: 2,
                maxWidth: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                }
              }}>

              <FormControl fullWidth margin="normal">
                <label htmlFor="title">
                  Title
                </label>
                <TextField
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <label htmlFor="description">
                  Description
                </label>
                <TextField
                  multiline
                  rows={4}
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <label htmlFor="amount">
                  Amount
                </label>
                <TextField
                  id="amount"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                  InputProps={{
                    startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <label htmlFor="start-at">
                  Start At
                </label>
                <TextField
                  id="start-at"
                  type="datetime-local"
                  value={startAt}
                  onChange={(e) => setStartAt(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <label htmlFor="end-at">
                  End At
                </label>
                <TextField
                  id="end-at"
                  type="datetime-local"
                  value={endAt}
                  onChange={(e) => setEndAt(e.target.value)}
                  required
                />
              </FormControl>

              {isDesktop && (
                <LoadingButton
                  loading={isLoading}
                  type="submit" variant="contained" color="primary">
                  {editID ? 'Update Project' : 'Create Project'}
                </LoadingButton>
              )}
            </Box>
          </Grid>)}
          {isDesktop && (
            <Grid item xs={12} lg={5} xl={4}>
              <SearchUser setUser={setSelectedUser} />
              <ProjectSummary
                title={title}
                description={description}
                amount={amount}
                startAt={startAt}
                endAt={endAt}
              />
            </Grid>
          )}
          {!isDesktop &&
            <Grid item xs={12}>
              {step === 2 && (
                <SearchUser setUser={setSelectedUser} />
              )}
              {step === 3 && (
                <ProjectSummary
                  title={title}
                  description={description}
                  amount={amount}
                  startAt={startAt}
                  endAt={endAt}
                />
              )}
              <Box mt={2} display={'flex'} justifyContent={'space-between'}>
                <Button
                  onClick={() => {
                    if (editID && step === 3) {
                      setStep(step > 0 && 1)
                      return
                    }
                    setStep(step > 0 && step - 1)
                  }}
                  variant="outlined"
                  disabled={step === 1}
                  color="primary"
                  sx={{ alignSelf: 'flex-end' }}
                >
                  Previous
                </Button>
                {step !== 3 && (
                  <Button
                    onClick={() => {
                      if (editID && step === 1) {
                        setStep(step < 3 && 3)
                        return
                      }
                      setStep(step < 3 && step + 1)
                    }}
                    variant="contained"
                    color="primary"
                    disabled={step === 3}
                    sx={{ alignSelf: 'flex-end' }}
                  >
                    Next
                  </Button>
                )}
                {step === 3 && (
                  <LoadingButton
                    loading={isLoading}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary">
                    {editID ? 'Update Project' : 'Create Project'}
                  </LoadingButton>
                )}
              </Box>
            </Grid>
          }
        </Grid>
      )}
      {step === 4 && <SuccessMsg text={`Project ${editID ? 'Updated' : "Created"} Successfully`} label={'Projects'} target={'projects'} clear={clearAll} setStep={setStep} />}
    </>
  );
};

export default ProjectEditor;