import { Button, IconButton, InputAdornment, Pagination, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Box, Stack, useMediaQuery } from '@mui/system';
import { TabPanel, a11yProps } from '../components/TabPanel';
import SwipeableViews from "react-swipeable-views-react-18-fix"
import { GoDotFill } from 'react-icons/go';
import Stat from '../components/Stat';
import ProjectItem, { ProjectItemSkeleton } from '../components/ProjectItem';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { IoMdArrowForward } from "react-icons/io";
import { isEmpty } from 'underscore';
import Placeholder from '../components/Placeholder';
import { ReactComponent as NoData } from '../assets/no_data.svg'
import { makeRequest } from '../util/AxiosInstance';
import { useQuery } from '@tanstack/react-query';
import { navigationContext } from '../zustand';
import { CiSearch } from "react-icons/ci";
import useDebounce from '../components/debounce';

function Projects() {
    const [value, setValue] = useState(0);
    const { ongoing, completed, pending, setOngoingPage, setPendingPage, setCompletedPage } = navigationContext();
    let PENDING = 1
    let ONGOING = 2
    let COMPLETED = 3
    const theme = useTheme();
    const [countToShow, setCountToShow] = useState(6);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(1);
    const [search, setSearch] = useState('');
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const debouncedSearch = useDebounce(search, 300);
    // const theme = useTheme();
    const fetchProjects = async () => {
        let status = 1
        try {
            switch (value) {
                case 0:
                    status = ONGOING;
                    break;
                case 1:
                    status = PENDING;
                    break;
                case 2:
                    status = COMPLETED;
                    break;
                default:
                    status = PENDING;
            }
            let params = new URLSearchParams();

            params.append('status', status);
            params.append('page', page);
            params.append('perPage', countToShow);
            if (search) {
                params.append('search', search);
            }

            return await makeRequest('GET', `/projects?${params.toString()}`)
                .then(response => {
                    let res = response.data.data
                    let total = res.total
                    if (total > 0) {
                        let page_size = Math.ceil(total / countToShow)
                        setPageSize(page_size)
                    }
                    let data = res.data
                    return data

                })
        } catch (error) {
            console.log(error);
        }
    };

    const {
        data,
        isLoading,
        isError,
        refetch,
        status,
    } = useQuery({
        queryKey: ['projects', value, page, debouncedSearch],
        queryFn: fetchProjects,
    });

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        switch (value) {
            case 0:
                setOngoingPage(newPage);
                break;
            case 1:
                setPendingPage(newPage);
                break;
            case 2:
                setCompletedPage(newPage);
                break;
            default:
                break;
        }
    };

    const handleChangeIndex = (e, index) => {
        console.log('index: ', index);
        switch (index) {
            case 0:
                setPage(ongoing.page);
                break;
            case 1:
                setPage(pending.page);
                break;
            case 2:
                setPage(completed.page);
                break;
            default:
                break;
        }
        setValue(index);
    };
    const renderProjectSkeletons = () => {
        let size = countToShow > 1 ? countToShow : 6;
        return (
            <motion.div className="project-list">
                {Array(size).fill(0).map((_, index) => (
                    <ProjectItemSkeleton key={index} />
                ))}
            </motion.div>
        )
    };

    const renderProjects = () => {
        if (status === 'loading') return <Placeholder icon={NoData} text={'Loading projects...'} />;
        if (status === 'error') return <Placeholder icon={NoData} text={'Error loading projects'} />;

        const projects = data || [];

        if (isEmpty(projects) && isLoading) return renderProjectSkeletons();

        if (isEmpty(projects) || !projects) {
            return <Placeholder icon={NoData} text={`No ${value === 0 ? 'ongoing' : value === 1 ? 'pending' : 'completed'} projects`} />;
        }

        return (
            <>
                <motion.div className="project-list">
                    {projects && projects.map((project) => (
                        <ProjectItem status key={project.id} project={project} />
                    ))}
                </motion.div>
                {pageSize > 1 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                        <Pagination
                            count={pageSize}
                            page={page}
                            onChange={handlePageChange}
                        />
                    </Box>
                )}
            </>
        );
    };

    return (
        <>
            <Box className="stat-bar items-center" sx={{
                my: 4,
                maxWidth: {
                    lg: "600px",
                },
                display: 'none'
            }}>
                <Box
                    className="stats"
                    sx={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "space-between",
                        gap: "1rem",
                        flex: 1,
                        maxWidth: {
                            xs: "100%",
                            sm: "70%",
                            md: '65%'
                        },
                    }}
                >
                    <Stat text="In Progress" value={10} />
                    <span className="dot">
                        <GoDotFill style={{ color: "#e1e1e1" }} />
                    </span>
                    <Stat text="Total projects" value={10} />
                </Box>
            </Box>
            <Box className="project-list-wrapper">
                <Box className="project-list-header" sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                    mb: {
                        md: 2
                    }
                }}>
                    <Typography
                        variant="h5" className="title" gutterBottom>
                        Projects
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                        {!isMobile && (
                            <form action="#" onSubmit={(e) => {
                                e.preventDefault();
                                refetch();
                            }}>
                                <TextField
                                    label="Search"
                                    variant='standard'
                                    value={search}
                                    color='secondary'
                                    onChange={(e) => setSearch(e.target.value)}
                                    sx={{ width: '300px' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton size='small'>
                                                    <CiSearch />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </form>
                        )}
                        <Button
                            component={Link}
                            to="/project/edit"
                            variant='contained'
                            endIcon={<IoMdArrowForward />}
                        >Create</Button>
                    </Box>
                </Box>
                {isMobile && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <form style={{ width: '100%' }} action="#" onSubmit={(e) => {
                            e.preventDefault();
                            refetch();
                        }}>
                            <TextField
                                placeholder="Search"
                                size='small'
                                fullWidth
                                color='secondary'
                                variant='outlined'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                sx={{ width: '100%', borderRadius: '1rem', my: 2 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton size='small'>
                                                <CiSearch />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </form>
                    </Box>
                )}
                <Tabs
                    className="tab-bar"
                    value={value}
                    onChange={handleChangeIndex}
                    textColor="inherit"
                    indicatorColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab className="tab-item" label="Ongoing" {...a11yProps(0)} />
                    <Tab className="tab-item" label="Pending" {...a11yProps(1)} />
                    <Tab className="tab-item" label="Completed" {...a11yProps(2)} />
                </Tabs>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        {renderProjects()}
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        {renderProjects()}
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        {renderProjects()}
                    </TabPanel>
                </SwipeableViews>
            </Box>
        </>
    )
}

export default Projects