import React from 'react';
import moment from 'moment-timezone';
import { Box, Paper, Typography } from '@mui/material';

const convertToUserLocalTime = (date, timezone) => {
    return moment.tz(date, timezone).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');
};

const BookingSummary = ({ title, description, startAt, endAt, selectedTimeZone, recipient }) => {
    const userTimeZone = moment.tz.guess();
    const localStartAt = startAt ? convertToUserLocalTime(startAt, selectedTimeZone) : '---';
    const localEndAt = endAt ? convertToUserLocalTime(endAt, selectedTimeZone) : '---';

    return (
        <Box
            sx={{
                textTransform: 'capitalize',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
            className='booking-summary card-box'
        >
            <Typography component="div" variant="h6" gutterBottom>Booking Summary</Typography>
            <Typography component="div" variant="body1"><span>Title</span> <p>{title ?? '---'}</p></Typography>
            <Typography component="div" variant="body1"><span>Description</span> <p>{description ?? '---'}</p></Typography>
            <Typography component="div" variant="body1"><span>Start Date (User Time)</span> <p>{startAt ?? '---'}</p></Typography>
            <Typography component="div" variant="body1"><span>End Date (User Time)</span> <p>{endAt ?? '---'}</p></Typography>
            <Typography component="div" variant="body1"><span>Meeting Timezone</span> <p>{selectedTimeZone}</p></Typography>
            <Typography component="div" variant="body1"><span>Start Date (Your Time)</span> <p>{localStartAt ?? '---'}</p></Typography>
            <Typography component="div" variant="body1"><span>End Date (Your Time)</span> <p>{localEndAt ?? '---'}</p></Typography>
            <Typography component="div" variant="body1"><span>Your Timezone</span> <p>{userTimeZone}</p></Typography>
            <Typography component="div" variant="body1"><span>Recipient</span> <p>{recipient ? `${recipient.first_name} ${recipient.last_name}` : '---'}</p></Typography>
            <Typography component="div" variant="body1"><span>Email</span> <p>{recipient ? recipient.email : '---'}</p></Typography>
        </Box>
    );
};

export default BookingSummary;