import React, { useEffect, useState } from 'react';
import { Button, TextField, TablePagination, Grid, InputAdornment, IconButton, Paper, Typography, Skeleton, useMediaQuery } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { IoSearchOutline } from "react-icons/io5";
import generatePDF from './GeneratePdf';
import useDebounce from './debounce';
import { makeRequest } from '../util/AxiosInstance';
import { formatCurrency, formatDate, sleep } from '../util/functions';
import { SlCloudDownload } from "react-icons/sl";
import { useTheme } from '@emotion/react';

const BillingTable = ({ status, children }) => {
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const debouncedSearch = useDebounce(search, 300);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const fetchOrders = async () => {
        try {
            // await sleep(50000)
            const params = new URLSearchParams();
            params.append('status', status);
            params.append('page', page);
            params.append('perPage', rowsPerPage);

            if (debouncedSearch) {
                params.append('search', debouncedSearch);
            }
            const response = await makeRequest('GET', `project-orders/?${params.toString()}`);
            return response.data.data;
        } catch (error) {
            console.error(error);
            return {
                data: {
                    data: []
                },
                current_page: 1,
                last_page: 1,
            };
        }
    };

    const { data, isFetching, isLoading, refetch } = useQuery(
        {
            queryKey: ['orders', status, debouncedSearch, page, rowsPerPage],
            queryFn: fetchOrders,
            keepPreviousData: true,
        }
    );



    const handleSearchChange = (event) => {
        setPage(1)
        setSearch(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const generateReceipt = (order) => {
        generatePDF(order, 'Receipt');
    };

    const generateInvoice = (order) => {
        generatePDF(order, 'Invoice');
    };

    const pay = (order) => {
        console.log(order)
    }

    const filteredOrders = data?.data || [];
    useEffect(() => {
        console.log(page)
        refetch()
    }, [page])

    useEffect(() => {
        if (debouncedSearch) {
            refetch();
        }
    }, [debouncedSearch, refetch]);


    const renderSkeleton = () => {
        return <>
            {Array.from({ length: rowsPerPage }, (_, index) => (
                <tr key={index}>
                    <td colSpan="100%" style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Skeleton width={'30%'} variant="text" />
                            <Skeleton width={'15%'} variant="text" />
                            <Skeleton width={'20%'} variant="text" />
                            <Skeleton width={'20%'} variant="text" />
                            {/* <Skeleton width={'10%'} variant="text" /> */}
                        </div>
                    </td>
                </tr>
            ))}
        </>
    }

    return (
        <Paper className='billing-table-wrapper' sx={{ padding: '1rem', borderRadius: '1rem' }}>
            <Grid container className='billing-table-header'>
                <Grid item xs={12} md={8}>
                    {children}
                </Grid>
                <Grid item xs={12} md={4} sx={{
                    ml: 'auto', display: 'flex', justifyContent: 'flex-start', mt: {
                        xs: '1rem',
                        md: '0'
                    }
                }}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton size='small'>
                                        <IoSearchOutline />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            marginBottom: '20px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    borderRight: 'none',
                                },
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <table className='use-table'>
                <thead>
                    <tr>
                        <th>Project</th>
                        <th>Amount</th>
                        {isMobile ? <></> : <>
                            <th>Date</th>
                        </>}
                        <th>{+status === 1 ? 'Receipt' : 'Invoice'}</th>
                        {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? <>
                        {renderSkeleton()}
                    </> : filteredOrders.length > 0 ? filteredOrders.map(order => (
                        <tr key={order.id}>
                            <td style={{ textTransform: 'capitalize' }}>{order.title}</td>
                            <td>{formatCurrency(order.amount)}</td>
                            {isMobile ? <></> : <>
                                <td>{formatDate(order.created_at)}</td>
                            </>}
                            <td>
                                {+order.status === 1 ? (
                                    <IconButton color='primary' variant="text" onClick={() => generateReceipt(order)}><SlCloudDownload /></IconButton>
                                ) : (
                                    <IconButton color='secondary' variant="text" onClick={() => generateInvoice(order)}><SlCloudDownload /></IconButton>
                                )}
                            </td>
                            {/* <td>
                                <Button variant="text" color='secondary' onClick={() => pay(order)}>Pay</Button>
                            </td> */}
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan="100%" style={{ textAlign: 'center' }}>
                                <Typography fontWeight={400} variant="subtitle">No orders available</Typography>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <TablePagination
                component="div"
                count={data?.total || 0} // Assuming your API returns total count
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Show"
                sx={{ display: 'flex', justifyContent: 'start' }}
            />
        </Paper>
    );
};

export default BillingTable;