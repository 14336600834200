import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box } from '@mui/material';
import { useTimeZoneStore } from '../zustand';
import { isEmpty } from 'underscore';

function TimeZoneSelect({ conversion, onTimeZoneSelect, setTzVal }) {
  const { timezones, fetchTimezones } = useTimeZoneStore();
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState(null);
  useEffect(() => {
    fetchTimezones();
  }, [fetchTimezones]);

  const handleChange = (selectedOption) => {
    setOption(selectedOption);
    if (onTimeZoneSelect) {
      onTimeZoneSelect(`${selectedOption.label}`);
    }
  };

  useEffect(() => {
    const optionsData = !isEmpty(timezones) ? timezones.map((tz) => ({ value: `${tz.id}`, label: tz.name })) : [];
    setOptions(optionsData);
  }, [timezones]);

  useEffect(() => {
    if (isEmpty(setTzVal)) return;
    let tz = timezones.find((tz) => tz.id == setTzVal)
    const dd = {
      value: `${tz.id}`,
      label: tz.name
    }
    setOption(dd);
    onTimeZoneSelect(`${tz.name}`);

  }, [setTzVal]);


  return (
    <Box>
      <Select
        value={option}
        options={options}
        onChange={handleChange}
        placeholder="Select a timezone"
        isSearchable
      />
    </Box>
  );
}

export default TimeZoneSelect;