import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import BillingTable from '../components/BillingTable'

const orderStatus = {
  PENDING: 0,
  SUCCESS: 1,
  FAILED: 2,
  CANCELLED: 3,
  REFUNDED: 4
}

function Billing() {
  return (
    <div>
      <Typography fontWeight={600}
        variant="h5">Billing</Typography>
      <Box className='billing-table-container'
        sx={{
          my: '2rem'
        }}>
        <BillingTable status={orderStatus.PENDING} children={
          <>
            <Typography fontWeight={600} mb='0.5rem' variant="body1">Pending Orders</Typography>
            <Typography variant="body2">Manage billing information and view invoices</Typography></>} />
      </Box>
      <Box className='billing-table-container' sx={{
        mt: '2rem'
      }}>
        <BillingTable status={orderStatus.SUCCESS} children={<>
          <Typography fontWeight={600} mb='0.5rem' variant="body1">Order History</Typography>
          <Typography variant="body2">Manage billing information and view receipts</Typography></>} />
      </Box >
    </div>
  )
}

export default Billing