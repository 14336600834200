import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Block } from "notiflix";
import BookingItem from "./BookingItem";
import { ReactComponent as Waiting } from '../assets/waiting.svg'
import { isArray, isEmpty } from "underscore";
import { makeRequest } from "../util/AxiosInstance";
import { useBookingCardStore } from "../zustand";
import { useNavigate } from "react-router-dom";

function BookingCard() {
    const { bookings, setBookings, isLoading, setIsLoading } = useBookingCardStore();
    const navigate = useNavigate();

    const fetchBooking = () => {
        makeRequest('GET', `/bookings?perPage=${4}`).then((res) => {
            let dd = res.data.data.data
            setBookings(dd);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (isLoading) {
            Block.circle('.booking-list')
        } else {
            Block.circle('.booking-list')
            Block.remove('.booking-list')
        }
    }, [isLoading]);

    useEffect(() => {
        fetchBooking();
    }, []);


    return <>
        <Box component={motion.div}
            sx={{
                height: {
                    lg: '100%',
                    xs: 'auto'
                },
                minHeight: {
                    lg: '100%',
                    xs: '300px'
                }
            }}
            className="card-item bookings">
            <div className="card-item-header">
                <Typography variant="h6">
                    Bookings
                </Typography>
                <Button
                    onClick={() => {
                        navigate('/bookings');
                    }}
                    disabled={isEmpty(bookings)}
                    color="secondary"
                    variant="text">View All</Button>
            </div>
            <div style={{ height: '100%' }} className="booking-list">
                {bookings && isArray(bookings) && bookings.length > 0 ? bookings.map((booking) => (
                    <BookingItem key={booking.id} booking={booking} />
                )) : <>
                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Waiting style={{ width: '150px', height: '150px' }} />
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            No bookings found
                        </Typography>
                    </Box>
                </>}
            </div>
        </Box>
    </>
}

export default BookingCard;