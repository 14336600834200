import React, { useEffect, useState } from "react";
import { CheckBox } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ReactComponent as LoginSVG } from "../assets/login.svg";
import { LoadingButton } from "@mui/lab";
import { Notify } from "notiflix";
import AuthWrapper from "../wrappers/AuthWrapper";
import { makeRequest } from "../util/AxiosInstance";
import { isEmpty } from "underscore";
import DisplayAlerts from "../shared/DisplayAlerts";
import PasswordField from "../shared/PasswordField";
import { userStore } from "../zustand";
import { handleError, sleep } from "../util/functions";
import { IndexedDBUtility } from "../util/IndexedDb";
import { API_URL, IndexDbStoreName, IndexedDbName } from "../zustand/const";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [apiErrors, setApiErrors] = useState(null);
  const { setUser, setToken } = userStore();

  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = (e) => {
    try {
      e.preventDefault();
      if (isEmpty(formData.email)) {
        setApiErrors("Please enter a valid email address");
        return false;
      }
      if (isEmpty(formData.password)) {
        setApiErrors("Please enter a password");
        return false;
      }
      setApiErrors(null);
      setLoading(true);
      makeRequest('post', `${API_URL}/login`, {
        email: formData.email,
        password: formData.password,
      })
        .then(async (response) => {
          if (response.status === 200) {
            if (!response.hasOwnProperty('data')) {
              console.log('No data');
              throw new Error("Invalid response"
              );
            }
            if (!response.data.hasOwnProperty('data')) {
              throw new Error("Invalid response"
              );
            }
            const { access_token, user } = response.data.data
            if (!access_token || !user) {
              throw new Error("Inappropriate login")
            }
            if (user && !user.hasOwnProperty('user_role')) {
              throw new Error("User is not an admin.")
            }
            if (+user.user_role.role_id !== 2) {
              // console.log(user.user_role);
              throw new Error("User is not an admin.")
            }

            let db = new IndexedDBUtility(IndexedDbName, IndexDbStoreName);
            await db.openDatabase();
            await db.writeToDatabase({ id: "jwt", jwt: access_token });

            // check if user email is verified.
            let test = await makeRequest('GET', '/get-user')
            if (test) {
              setUser(user)
              setToken(access_token)
            }
          } else {
            throw new Error("Login Failed")
          }
        }).then(async () => {
          await sleep(1000);
          Notify.success("Login Successful");
          navigate("/");
        })
        .catch((error) => {
          let msg = handleError(error)
          setApiErrors(msg);
        }).finally(() => {
          setLoading(false);
        });
    } catch (error) {
      let msg = handleError(error)
      Notify.failure(msg);
      setApiErrors(msg)
    }
  };

  return (
    <main className="authContainer">
      <div className="form_container">
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: '1rem'
        }}>

          <Typography variant="h4" mb={2} fontWeight={500}>
            Doshby
          </Typography>
          <Typography variant="h6" align="center">Admin Login</Typography>
          <Typography mb="1rem" variant="body2" align="left" gutterBottom>
            Login to your account using email and password
          </Typography>
        </Box>

        <form autoComplete="on" className="authForm" onSubmit={handleLogin}>
          <DisplayAlerts apiErrors={apiErrors} />
          <Grid
            container
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Grid item></Grid>
            <Grid item></Grid>
          </Grid>

          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            autoComplete="email"
          />
          <PasswordField
            fullWidth
            label="Password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            disableCheck={true}
            aria-describedby="password-helper-text"
            autoComplete="current-password"

          />
          <Grid
            container
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            {/* <Grid item xs={6}>
              <FormControlLabel
                sx={{ ml: 0 }}
                control={<CheckBox defaultChecked />}
                label="Remember Me"
              />
            </Grid> */}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start' }}>
              <Button variant="text" component={RouterLink} to="/forgot-password" sx={{
                textAlign: 'left'
              }}>Forgot Password</Button>
            </Grid>
          </Grid>
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            color="primary"
            loading={loading}
          >
            Login
          </LoadingButton>
        </form>
      </div>
    </main>
  );
}

export default Login;
