import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./style/style.css";
import App from "./App";
import Login from "./pages/Login";
import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import ErrorPage from "./pages/ErrorPage";
import Project from "./pages/Project";
import Account from "./pages/Account";
import Bookings from "./pages/Bookings";
import Billing from "./pages/Billing";
import PublicPages from "./wrappers/PublicPages";
import Chat from "./pages/Chat";
import Projects from "./pages/Projects";
import BookingEditor from "./pages/BookingEditor";
import ProjectEditor from "./pages/ProjectEditor";
import Portfolio from "./pages/Portfolio";
import PortfolioEditor from "./pages/PortfolioEditor";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
      {
        path: "project/:id",
        element: <Project />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "bookings",
        element: <Bookings />,
      },
      {
        path: "booking/edit/:id?",
        element: <BookingEditor />,
      },
      {
        path: "project/edit/:id?",
        element: <ProjectEditor />,
      },
      {
        path: "billing",
        element: <Billing />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "portfolio",
        element: <Portfolio />,
      },
      {
        path: "portfolio/edit/:id?",
        element: <PortfolioEditor />,
      },

    ],
  },
  {
    path: '/',
    element: <PublicPages />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
