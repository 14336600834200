import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, label, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeRequest } from '../util/AxiosInstance';
import { API_URL } from '../zustand/const';
import BackButton from '../components/BackButton';
import SearchUser from '../components/SearchUser';
import { Notify } from 'notiflix';
import { LoadingButton } from '@mui/lab';
import DisplayAlerts from '../shared/DisplayAlerts';
import { useParams } from 'react-router-dom';
import TimeZoneSelect from '../components/TimeZoneSelect';
import BookingSummary from '../components/BookingSummary';
import SuccessMsg from '../components/SuccessMsg';
import { formatDateForApi } from '../util/functions';
import { isEmpty } from 'underscore';

const BookingEditor = ({ onSuccess }) => {
    let { id: editID } = useParams();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startAt, setStartAt] = useState('');
    const [endAt, setEndAt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [apiErrors, setApiErrors] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedTimeZone, setSelectedTimeZone] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [step, setStep] = useState(1);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const [tz, setTz] = useState(null);

    const clearAll = () => {
        setTitle('');
        setDescription('');
        setStartAt('');
        setEndAt('');
        setSelectedUser(null);
        setSelectedTimeZone(null);
        setSuccessMsg(null);
        setApiErrors(null);
        setStep(1);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isEmpty(selectedUser)) {
            Notify.failure('Please select a recipient');
            return;
        }
        if (isEmpty(selectedTimeZone) || isEmpty(title) || isEmpty(description) || isEmpty(startAt) || isEmpty(endAt)) {
            Notify.failure('Please fill all fields');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('start_at', formatDateForApi(startAt));
        formData.append('end_at', formatDateForApi(endAt));
        formData.append('start_at_time_zone', selectedTimeZone);
        formData.append('end_at_time_zone', selectedTimeZone);
        formData.append('user_id', selectedUser.id);
        if (editID) {
            formData.append('status', 1);
        }
        setIsLoading(true);

        try {
            makeRequest('POST', editID ? `${API_URL}/edit-booking/${editID}` : `${API_URL}/create-booking`, formData)
                .then((response) => {
                    if (response.data.status) {
                        Notify.success('Booking created successfully');
                        setSuccessMsg('Booking created successfully');
                        setStep(4);
                        console.log(response.data);
                    } else {
                        Notify.failure('Failed to create booking');
                    }
                }).catch((error) => {
                    Notify.failure('Failed to create booking: ' + error.message);
                    console.error('Error:', error);
                    setApiErrors(error.response.data.message ?? error.message);
                }).finally(() => {
                    setIsLoading(false);
                })
        } catch (error) {
            Notify.failure('Failed to create booking: ' + error.message);
            console.error('Error:', error);
        }
    };

    const handleTimeZoneSelect = (value) => {

        setSelectedTimeZone(value);
    }

    useEffect(() => {
        if (editID) {
            makeRequest('GET', `${API_URL}/booking/${editID}`).then((response) => {
                const data = response.data.data;
                setTitle(data.title);
                setDescription(data.description);
                setTz(data.start_at_time_zone_id);
                setStartAt(data.start_at);
                setEndAt(data.end_at);
                return data
            }).then((booking) => {
                console.log('Getting data for user:', booking.user_id);

                makeRequest('GET', `${API_URL}/get-user?user_id=${booking.user_id}`).then((response) => {
                    const data = response.data.data;
                    setSelectedUser(data);
                }).catch((error) => {
                    Notify.failure('Failed to fetch user data: ' + error.message);
                    console.error('Error:', error);
                });
            })
                .catch((error) => {
                    Notify.failure('Failed to fetch booking data: ' + error.message);
                    console.error('Error:', error);
                });

        }
    }, [editID]);

    return (
        <>
            <Box sx={{
                marginBottom: {
                    xs: 3.5,
                    sm: 5,
                }
            }}
                display={'flex'} alignItems={'center'}>
                <BackButton />
                <Typography fontWeight={'600'} variant="h5" mb={0}>{editID ? 'Update Booking' : 'Create Booking'}</Typography>
            </Box>
            <DisplayAlerts success={successMsg} apiErrors={apiErrors} />
            {step !== 4 && (
                <Grid container spacing={2}>
                    {(isDesktop || step === 1) && (<Grid item xs={12} lg={7} xl={6}>
                        <Box component="form" className='booking-form' onSubmit={handleSubmit}
                            sx={{
                                display: 'flex', flexDirection: 'column', gap: 2, backgroundColor: 'white',
                                padding: 2,
                                maxWidth: {
                                    xs: '100%',
                                    sm: '100%',
                                    md: '100%',
                                    lg: '100%',
                                }
                            }}>
                            <FormControl fullWidth margin="normal">
                                <label htmlFor="title">
                                    Title
                                </label>
                                <TextField
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <label htmlFor="description">
                                    Description
                                </label>
                                <TextField
                                    multiline
                                    rows={4}
                                    id="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <label htmlFor="timezone">
                                    Select Timezone
                                </label>
                                <TimeZoneSelect setTzVal={tz} conversion={true} onTimeZoneSelect={handleTimeZoneSelect} />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <label htmlFor="start-at">
                                    Start At
                                </label>
                                <TextField
                                    id="start-at"
                                    type="datetime-local"
                                    value={startAt}
                                    onChange={(e) => setStartAt(e.target.value)}
                                    required

                                />
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <label htmlFor="end-at">
                                    End At
                                </label>
                                <TextField
                                    id="end-at"
                                    type="datetime-local"
                                    value={endAt}
                                    onChange={(e) => setEndAt(e.target.value)}
                                    required

                                />
                            </FormControl>
                            {isDesktop && (
                                <LoadingButton
                                    loading={isLoading}
                                    type="submit" variant="contained" color="primary">
                                    {editID ? 'Update Booking' : 'Create Booking'}
                                </LoadingButton>
                            )}
                        </Box>
                    </Grid>)}
                    {isDesktop && (
                        <Grid item xs={12} lg={5} xl={4}>
                            <SearchUser setUser={setSelectedUser} user={selectedUser} />
                            <BookingSummary
                                title={title}
                                description={description}
                                startAt={startAt}
                                endAt={endAt}
                                selectedTimeZone={selectedTimeZone}
                                recipient={selectedUser}
                            />
                        </Grid>
                    )}
                    {!isDesktop &&
                        <Grid item xs={12}>

                            {step === 2 && (
                                <SearchUser setUser={setSelectedUser} user={selectedUser} />
                            )}
                            {step === 3 && (
                                <BookingSummary
                                    title={title}
                                    description={description}
                                    startAt={startAt}
                                    endAt={endAt}
                                    selectedTimeZone={selectedTimeZone}
                                    recipient={selectedUser}
                                />
                            )}
                            <Box mt={2} display={'flex'} justifyContent={'space-between'}>

                                <Button
                                    onClick={() => setStep(step > 0 && step - 1)}
                                    variant="outlined"
                                    disabled={step === 1}
                                    color="primary"
                                    sx={{ alignSelf: 'flex-end' }}
                                >
                                    Previous
                                </Button>
                                {step !== 3 && (<>
                                    <Button
                                        onClick={() => setStep(step < 3 && step + 1)}
                                        variant="contained"
                                        color="primary"
                                        disabled={step === 3}
                                        sx={{ alignSelf: 'flex-end' }}
                                    >
                                        Next
                                    </Button>
                                </>
                                )}
                                {step === 3 && (
                                    <LoadingButton
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        type="submit" variant="contained" color="primary">
                                        {editID ? 'Update Booking' : 'Create Booking'}
                                    </LoadingButton>
                                )}
                            </Box>
                        </Grid>
                    }
                </Grid>)}
            {step === 4 && <SuccessMsg text={'Booking created successfully.'} label={'Bookings'} target={'bookings'} clear={clearAll} setStep={setStep} />}
        </>

    );
};

export default BookingEditor;