import jsPDF from 'jspdf';
import 'jspdf-autotable';
import InvoiceBG from '../assets/invoice.png';

const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

const generatePDF = (order, type) => {
    const user = {
        name: "Gbenga Olajide",
    };
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 210;
    const pageHeight = 297;

    const img = new Image();
    img.src = InvoiceBG;
    img.onload = function () {
        pdf.addImage(img, 'JPEG', 0, 0, imgWidth, pageHeight);
        pdf.setFontSize(16);
        pdf.setFont('helvetica', 'normal');
        pdf.text('Doshby', 10, 40);
        pdf.setFontSize(30);
        pdf.setFont('helvetica', 'bold');
        pdf.text('Invoice', imgWidth - 10, 40, { align: 'right' });
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`Invoice To:`, 10, 60);
        pdf.text(user.name.toUpperCase(), 10, 68);
        pdf.text(`Invoice Date:`, imgWidth - 10, 60, { align: 'right' });
        pdf.text(formatDate(order.project_detail.created_at), imgWidth - 10, 68, { align: 'right' });

        pdf.autoTable({
            startY: 80,
            body: [
                ['Project Title', order.title],
                ['Description', order.description],
                ['Amount', order.project_detail.amount.toString()],
            ],
            theme: 'grid',
            headStyles: { fillColor: [0, 0, 0] },
            styles: { fontSize: 12 },
        });
        pdf.setFontSize(12);
        pdf.text('For support, contact us at: support@doshby.com', 10, pageHeight - 20);
        pdf.save(`${type}_${order.id}.pdf`);
    };

    img.onerror = function (error) {
        console.error("Image loading error: ", error);
    };
};

export default generatePDF;