import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { IoAddOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import PortfolioList from '../components/PortfolioList'

function Portfolio() {
    return (
        <Box>
            <Grid container>
                <Grid item>
                    <Typography fontWeight={500} variant="h4" gutterBottom>
                        Portfolio
                    </Typography>
                </Grid>
                <Grid item ml="auto">
                    <Button
                        component={Link}
                        to="/portfolio/edit"
                        variant='contained'
                        endIcon={<IoAddOutline />}
                    >Create New</Button>
                </Grid>
            </Grid>
            <Box>
                <PortfolioList />
            </Box>
        </Box>
    )
}

export default Portfolio