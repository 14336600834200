import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'underscore';
import { userStore } from '../zustand';

const AuthWrapper = ({ children }) => {
    const navigate = useNavigate();

    const { user, token, logout } = userStore();
    useEffect(() => {
        if (isEmpty(token)) {
            logout().then(() => {
                navigate('/login');
            }).catch(() => {
                console.log('error cant redirect');
            });
        }
    }, [token, logout, navigate]);

    if (!isEmpty(token)) {
        return (
            <>
                {children}
            </>
        );
    }
    return <>
        Redirecting to login page
    </>;
};

export default AuthWrapper;