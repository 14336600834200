import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paper, Typography, CircularProgress, Box, Modal, IconButton } from '@mui/material';
import { makeRequest } from '../util/AxiosInstance';
import { API_URL } from '../zustand/const';
import { handleError } from '../util/functions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Delete } from '@mui/icons-material';

const ImageUpload = ({ setImageOrder, files, setFiles }) => {
    const [uploading, setUploading] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const onDrop = useCallback(async (acceptedFiles) => {
        const newFiles = acceptedFiles.map(file => ({
            file,
            preview: URL.createObjectURL(file),
            uploading: false,
            id: Math.random().toString(36).substring(7)
        }));
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
        setFilesToUpload(prevFiles => [...prevFiles, ...newFiles]);
    }, []);

    useEffect(() => {
        upload();
    }, [filesToUpload]);


    useEffect(() => {
        setImageOrder(files)
        if (files.length === 0 && modalOpen) {
            closeModal();
        }
    }, [files, currentImageIndex]);

    const deleteImage = (img) => {
        if (img) {
            let fileId = img.id
            let id = img && img.uploadData ? img.uploadData?.id : null
            if (id) {
                let currentIndex = files.findIndex(f => f.id === fileId);
                let nextIndex = currentIndex < files.length - 1 ? currentIndex + 1 : 0;
                setCurrentImageIndex(nextIndex);
                setFiles(prevFiles => prevFiles.filter(f => f.id !== fileId));
                makeRequest('DELETE', `${API_URL}/file/${id}`).then(res => {
                    console.log(res);
                }).catch(err => {
                    console.error(err);
                });
            }
        }
    }
    const upload = async () => {
        // const filesDataToUpload = filesToUpload.filter(f => !f.id);
        if (filesToUpload.length === 0) return;
        setUploading(true);
        try {
            await Promise.all(filesToUpload.map(async (fileObj, index) => {
                setFiles(prevFiles => prevFiles.map((f, i) => {
                    return f.id === fileObj.id ? { ...f, uploading: true } : f
                }
                ));

                let formData = new FormData();
                formData.append('file', fileObj.file);
                const res = await makeRequest('POST', `${API_URL}/file`, formData, {
                    'Content-Type': 'multipart/form-data'
                });

                const uploadedImage = res.data.data;
                setFiles(prevFiles => prevFiles.map((f, i) =>
                    f.id === fileObj.id ? { ...f, uploading: false, uploadData: uploadedImage } : f
                ));
                setFilesToUpload([])
            }));
        } catch (error) {
            console.error('Upload failed:', error);
            handleError(error);
        } finally {
            setUploading(false);
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(files);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setFiles(items);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: true,
    });

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setModalOpen(true);
    };

    const closeModal = () => setModalOpen(false);

    const nextImage = () => setCurrentImageIndex((prev) => (prev + 1) % files.length);
    const prevImage = () => setCurrentImageIndex((prev) => (prev - 1 + files.length) % files.length);

    return (
        <>
            <Paper
                className='image-upload'
                {...getRootProps()}
                sx={{
                    p: 2,
                    border: '2px dashed #ccc',
                    borderRadius: 2,
                    textAlign: 'center',
                    cursor: 'pointer',
                    backgroundColor: isDragActive ? '#f0f0f0' : 'white',
                }}
            >
                <input {...getInputProps()} />
                <Typography>Drag and drop images here, or click to select</Typography>
            </Paper>

            {files && <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="images" direction="horizontal">
                    {(provided) => (
                        <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}
                        >
                            {files.map((file, index) => (
                                <Draggable key={file.preview} draggableId={file.preview} index={index}>
                                    {(provided) => (
                                        <Box
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            sx={{
                                                width: 100,
                                                height: 100,
                                                m: 1,
                                                position: 'relative',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => openModal(index)}
                                        >
                                            <img
                                                src={file.preview}
                                                alt="preview"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                            {file.uploading && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        backgroundColor: 'rgba(0,0,0,0.7)',
                                                    }}
                                                >
                                                    <CircularProgress sx={{ color: 'white' }} size={24} />
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>}

            {files && <Modal
                open={modalOpen}
                onClose={closeModal}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    className='image-upload-modal'
                    sx={{
                        position: 'relative', maxWidth: '90%', maxHeight: '90%',
                    }}>
                    <IconButton
                        color='primary'
                        className='image-upload-button'
                        onClick={closeModal}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <IconButton
                        color='primary'
                        className='image-upload-button'
                        onClick={() => deleteImage(files[currentImageIndex])}
                        sx={{ position: 'absolute', top: 10, right: 70 }}
                    >
                        <Delete />
                    </IconButton>
                    <img
                        src={files[currentImageIndex]?.preview}
                        alt="full preview"
                        style={{ maxWidth: '100%', maxHeight: '90vh' }}
                    />
                    <IconButton
                        color='primary'
                        className='image-upload-button'
                        onClick={prevImage}
                        sx={{ position: 'absolute', left: 10, top: '50%' }}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton
                        color='primary'
                        className='image-upload-button'
                        onClick={nextImage}
                        sx={{ position: 'absolute', right: 10, top: '50%' }}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>
            </Modal >}
        </>
    );
};

export default ImageUpload;