import { Typography } from "@mui/material";
import CountUp from 'react-countup';
import { formatNumber } from "../util/functions";

function Stat({ text, value, prefix = '', icon, color }) {
    return (
        <div className="stat">
            <Typography className="text" variant="subtitle2">{text}</Typography>
            <Typography variant="h5" fontWeight={600}>
                {prefix && <span>{prefix}</span>}
                <CountUp end={+value} formattingFn={formatNumber} />
            </Typography>
        </div>
    );
}

export default Stat;