import React, { useEffect, useState } from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { motion } from "framer-motion";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../zustand/const";
import { Outlet, useLocation } from "react-router-dom";

function PublicPages({ children }) {

    const [tag, setTag] = useState("")

    const theme = createTheme({
        palette: {
            primary: {
                main: PRIMARY_COLOR,
            },
            secondary: {
                main: SECONDARY_COLOR,
            },
        },
        typography: {
            fontFamily: "Inter, sans-serif",
        },
    });

    const { pathname } = useLocation()
    useEffect(() => {
        const pages = ["/login", "/forgot-password"];
        if (pages.includes(pathname)) {
            setTag("auth-page")
        }
    }, [pathname])

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <motion.div
                    className={`app-wrapper ${tag}`}>
                    {children}
                    <Outlet />
                </motion.div>
            </ThemeProvider>
        </>
    );
}

export default PublicPages;
