import React, { useState, useEffect, useCallback } from 'react';
import { Button, Box, Typography, List, ListItem, ListItemText, TextField, Grid, InputAdornment, IconButton } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../zustand/const';
import { IoSearchOutline } from 'react-icons/io5';
import { makeRequest } from '../util/AxiosInstance';
import { isEmpty } from 'underscore';
import { LoadingButton } from '@mui/lab';

function SearchUser({ setUser, user }) {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedUser, setSelectedUser] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }
    const debouncedFetchUsers = useCallback(debounce((term) => {
        fetchUsers(1, term);
    }, 200), []);

    useEffect(() => {
        fetchUsers(currentPage);
    }, [currentPage]);

    const fetchUsers = async (page, searchterm = '') => {
        if (searchterm === '') {
            return;
        }
        setIsLoading(true);
        try {
            const params = new URLSearchParams();
            params.append('page', page);
            params.append('first_name', searchterm);
            params.append('last_name', searchterm);
            params.append('email', searchterm);
            console.log(params.toString());
            let url = `${API_URL}/get-users?${params.toString()}`;
            const response = await makeRequest('GET', url);
            const data = response.data.data;
            setUsers(data.data);
            setTotalPages(data.last_page);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectUser = (user) => {
        setSelectedUser(user);
        setUser(user)
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedFetchUsers(value);
    };

    const filteredUsers = users.filter((user) =>
        user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        if (user) {
            setSelectedUser(user)
            setUser(user)
        }
    }, [user])

    return (
        <Box className="search-user" padding={2}>
            <Grid display={'flex'} container alignItems={'center'}>
                <Grid item xs={6}>
                    <Typography variant="h6">Search Users</Typography>
                </Grid>
                <Grid item xs={6} ml="auto">
                    <TextField
                        variant="standard"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        fullWidth
                        size='small'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LoadingButton
                                        loading={isLoading}
                                        onClick={(e) => {
                                            fetchUsers(1, searchTerm);
                                        }}
                                        size='small'
                                    >
                                        <IoSearchOutline />
                                    </LoadingButton>
                                </InputAdornment>
                            ),
                        }}

                    />
                </Grid>
            </Grid>
            {selectedUser && (
                <Box sx={{
                    width: '100%',
                    border: '1px solid #ccc',
                    padding: '1rem',

                }} marginBottom={2}>
                    <Grid container>
                        <Grid item xs="6">
                            <Typography fontWeight={500} variant="body2" textTransform={'uppercase'}>Selected User</Typography>
                        </Grid>
                        <Grid item xs="6" textAlign={'right'}>
                            <Button
                                size='small' variant="text"
                                color='error'
                                onClick={() => handleSelectUser(null)}>Remove</Button>
                        </Grid>
                    </Grid>
                    <Typography>Name: {selectedUser.first_name} {selectedUser.last_name}</Typography>
                    <Typography>Email: {selectedUser.email}</Typography>
                </Box>
            )}
            {!isEmpty(filteredUsers) && (
                <Typography textAlign={'left'}
                    width={'100%'}
                    className='useLightColor'
                    fontWeight={500}
                    textTransform={'uppercase'}
                    variant="body2">Search Results</Typography>
            )}
            {isEmpty(filteredUsers) && <>
                <Typography fontWeight={300} className='useLightColor' variant='subtitle' textAlign={'left'}>
                    Enter a search term to find users
                </Typography>
            </>}
            <List sx={{
                width: '100%',
            }}>
                {filteredUsers.map((user) => (
                    <ListItem sx={{
                        backgroundColor: '#f9f9f9',
                    }} key={user.id} divider>
                        <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.email} />
                        {selectedUser && selectedUser.id === user.id ? (
                            <Typography
                                textTransform={'uppercase'}
                                variant='body2'
                                color='green'
                            >Selected</Typography>
                        ) : <>
                            <Button variant="text" onClick={() => handleSelectUser(user)}>Select</Button>
                        </>}
                    </ListItem>
                ))}
            </List>
            {totalPages > 1 && (
                <Box width="100%" display="flex" justifyContent="space-between" marginTop={2}>
                    <Button variant="contained" onClick={handlePreviousPage} disabled={currentPage === 1}>
                        Previous
                    </Button>
                    <Button variant="contained" onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Next
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default SearchUser;