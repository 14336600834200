import { Box, Button, ButtonGroup, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { convertToUserLocalTime, formatDateFromApi, handleError } from '../util/functions';
import { makeRequest } from '../util/AxiosInstance';
import moment from 'moment-timezone';
import { useBookingModal, useTimeZoneStore } from '../zustand';
import { Block, Notify } from 'notiflix';
import { isEmpty } from 'underscore';
import Placeholder from './Placeholder';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const BookingModal = () => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const userTimeZone = moment.tz.guess();
    const [recipient, setRecipient] = useState(null)
    const { id, open, setOpen, booking } = useBookingModal()
    const { timezones } = useTimeZoneStore()
    let [recipientTimezone, setRecipientTimezone] = useState(null)
    const [isApproving, setIsApproving] = useState(false)
    const handleOpenClose = () => setOpen(!open);

    const fetchBooking = (id) => {
        // setLoading(true)
        Block.circle('.booking-summary-card')
        makeRequest('GET', `/booking/${id}`)
            .then((response) => {
                let data = response.data.data ?? {}
                const { start_at_time_zone_id } = data
                setRecipientTimezone(getTz(start_at_time_zone_id))
                setData(data)
            }).catch((error) => {
                let msg = handleError(error)
                setError(msg)
                console.log(error)
            }).finally(() => {
                setLoading(false)
                // Block.remove('.booking-summary-card')
            })
    }

    const localStartAt = data && data.hasOwnProperty('start_at') && recipientTimezone ? convertToUserLocalTime(data.start_at, recipientTimezone) : '---';
    const localEndAt = data && data.hasOwnProperty('end_at') && recipientTimezone ? convertToUserLocalTime(data.end_at, recipientTimezone) : '---';

    const fetchUser = (id) => {
        setLoading(true)
        makeRequest('GET', `/get-user?user_id=${id}`)
            .then((response) => {
                let data = response.data.data ?? {}
                setRecipient(data)
            }).catch((error) => {
                let msg = handleError(error)
                setError(msg)
                console.log(error)
            }).finally(() => {
                Block.remove('.booking-summary-card')
                setLoading(false)
            })
    }

    const getTz = (timezoneId) => {
        let timezone = timezones.find(tz => tz.id == timezoneId)
        return timezone ? timezone.name : ''
    }
    const approve = () => {
        setIsApproving(true)
        makeRequest('POST', `/edit-booking/${id}`, {
            status: '1'
        }).then((response) => {
            console.log(response)
            // handleOpenClose()
        }).catch((error) => {
            let msg = handleError(error)
            Notify.failure(msg)
            console.log(error)
        }).finally(() => {
            setIsApproving(false)
        }
        )
    }

    useEffect(() => {
        if (id) {
            fetchBooking(id)
        }

        if (booking.user_id) {
            fetchUser(booking.user_id)
        }
    }, [booking, id])

    return (
        <>
            <Modal open={open} onClose={handleOpenClose}>
                <Box
                    sx={{
                        textTransform: 'capitalize',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {
                            xs: '95%',
                            sm: '80%',
                            md: '50%',
                            lg: '40%',
                        },
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                    className='booking-summary card-box booking-summary-card'
                >
                    {!isEmpty(data) &&
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography component="div" variant="h6" gutterBottom>Booking Summary</Typography>
                                <ButtonGroup>
                                    <LoadingButton
                                        loading={isApproving}
                                        variant='text'
                                        onClick={approve}>Approve</LoadingButton>
                                    <Button
                                        component={Link}
                                        to={`/booking/edit/${booking.id}`}
                                        color={'secondary'}
                                        variant='text' >Edit</Button>
                                </ButtonGroup>
                            </Box>
                            <Typography component="div" variant="body1"><span>Title</span> <p>{data.title ? data.title : '---'}</p></Typography>
                            <Typography component="div" variant="body1"><span>Description</span> <p>{data.description ? data.description : '---'}</p></Typography>
                            <Typography component="div" variant="body1"><span>Meeting Timezone</span> <p>{booking && getTz(booking.start_at_time_zone_id)}</p></Typography>
                            <Typography component="div" variant="body1"><span>Start Date (User Time)</span> <p>{data.start_at ? formatDateFromApi(data.start_at) : '---'}</p></Typography>
                            <Typography component="div" variant="body1"><span>End Date (User Time)</span> <p>{data.end_at ? formatDateFromApi(data.end_at) : '---'}</p></Typography>
                            <Typography component="div" variant="body1"><span>Your Timezone</span> <p>{userTimeZone}</p></Typography>
                            <Typography component="div" variant="body1"><span>Start Date (Your Time)</span> <p>{formatDateFromApi(localStartAt) ?? '---'}</p></Typography>
                            <Typography component="div" variant="body1"><span>End Date (Your Time)</span> <p>{formatDateFromApi(localEndAt) ?? '---'}</p></Typography>
                            <Typography component="div" variant="body1"><span>Recipient</span> <p>{recipient ? `${recipient.first_name} ${recipient.last_name}` : '---'}</p></Typography>
                            <Typography component="div" variant="body1"><span>Email</span> <p>{recipient ? recipient.email : '---'}</p></Typography>
                        </>
                    }
                    {isEmpty(data) && <Placeholder text={'Unable to load data'} />}
                </Box>
            </Modal>
        </>)
}

export default BookingModal;