import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Paper } from '@mui/material';
import { makeRequest } from '../util/AxiosInstance';
import { Notify } from 'notiflix';
import { LoadingButton } from '@mui/lab';

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }
        setError('');
        setIsLoading(true);
        makeRequest('post', '/edit-user', {
            oldPassword,
            password: newPassword,
            confirm_password: confirmPassword,
        }).then((res) => {
            if (res.status === 200) {
                Notify('Password changed successfully');
            } else {
                setError(res.data.message);
            }
        }).catch((error) => {
            Notify.failure('Failed to change password');
            console.error(error);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <Paper sx={{ padding: 3, marginTop: 3 }}>
            <Typography variant="h5" mb={3} gutterBottom>
                Change Password
            </Typography>
            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
            >
                <TextField
                    label="Old Password"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                />
                <TextField
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <TextField
                    label="Confirm New Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                {error && <Typography color="error">{error}</Typography>}
                <LoadingButton
                    type="submit"
                    loading={isLoading}
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 2 }}
                >
                    Change Password
                </LoadingButton>
            </form>
        </Paper>
    );
};

export default ChangePassword;