import React, { useState } from "react";
import { motion } from "framer-motion";
import { analyticsStore } from "../zustand";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import GreetingComponent from "../components/GreetingComponent";
import Stat from "../components/Stat";
import ProjectChart from "../components/ProjectChart";
import BookingCard from "../components/BookingCard";
import ProjectCard from "../components/ProjectCard";
import DateRangeSelector from "../components/DateRangeSelector";
import ChatComponent from "../components/Chat";

function Aside() {
  return (
    <motion.div style={{ height: '100%' }} className="">
      <ChatComponent />
    </motion.div>)
}

function Home() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { data: analytics } = analyticsStore()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container className="home page-con" spacing={3}>
      <Grid display={'flex'} flexDirection={'column'} item xs={12} lg={8}>
        {isMobile && <GreetingComponent />}
        <Box
          className="stat-container"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={6} md={2.3}>
              <Stat text="Total Revenue" value={analytics ? analytics.total_amount_paid : 0} prefix="$" />
            </Grid>
            <Grid item xs={6} md={2.3}>
              <Stat text="Total Projects" value={analytics ? analytics.total_projects : 0} />
            </Grid>
            <Grid item xs={6} md={2.3}>
              <Stat text="Ongoing Projects" value={analytics ? analytics.total_ongoing_projects : 0} />
            </Grid>
            <Grid item xs={6} md={2.3}>
              <Stat text="Completed Projects" value={analytics ? analytics.total_completed_projects : 0} />
            </Grid>
            <Grid item xs={6} md={2.3}>
              <Stat text="Registered Users" value={analytics ? analytics.total_users : 0} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{
          backgroundColor: theme.palette.background.paper,
          padding: "1rem",
          borderRadius: "1rem",
        }} mt={'2rem'}>
          <Grid container alignItems={'center'} spacing={2} >
            <Grid item xs={4} md={6} lg={7}>
              <Typography variant="h5" className="title">
                Activity
              </Typography>
            </Grid>
            <Grid item xs={8} md={6} lg={5} display={'flex'} justifyContent={'end'}>
              <DateRangeSelector />

            </Grid>
          </Grid>
          <ProjectChart mode="line" currencyLabels={{ currency_1: 'NGN', currency_2: 'USD' }} />
        </Box>
        <Grid mt={'1.3rem'} container spacing={2} flex={'1'} className="">
          <Grid item xs={12} lg={6}>
            <BookingCard />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ProjectCard />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4}>
        {isDesktop && <>
          <Aside />
        </>}
      </Grid>
    </Grid>
  );
}

export default Home;
