import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Button, Chip, Divider, Grid, IconButton, Typography } from "@mui/material";
import { LuCalendarPlus } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { motion } from "framer-motion";
import { Box } from "@mui/system";
import { CgTimelapse } from "react-icons/cg";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";

function ProjectCardItem({ project }) {
    const navigate = useNavigate();

    const handleNavigate = (project) => {
        navigate(`/project/${project.id}`);
    }

    return (
        <Box component={motion.div} className="project-item-card" onClick={() => handleNavigate(project)}>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Typography variant="body1" fontWeight={"500"} mb={1}>
                        {project.name ?? `Project ${project.id ?? "#"}`}
                    </Typography>
                    <Typography style={{ display: "flex", alignItems: "center", gap: "5px" }} variant="caption">
                        <span style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                            <CgTimelapse />
                        </span>
                        <span>2 days left</span>
                    </Typography>
                </Grid>
                <Grid item xs={2} sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <IconButton size="small">
                        <HiOutlineArrowTopRightOnSquare />
                    </IconButton>
                </Grid>
            </Grid>

        </Box>
    );
}
export default ProjectCardItem;
