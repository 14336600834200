import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, TablePagination, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import BookingCard from '../components/BookingItemCard';
import BookingItemCard from '../components/BookingItemCard';
import { IoAddOutline, IoSearchOutline } from "react-icons/io5";
import { convertToUserLocalTime, handleError, sortBookings } from '../util/functions';
import { isEmpty } from 'underscore';
import Placeholder from '../components/Placeholder';
import { ReactComponent as Waiting } from '../assets/waiting.svg'
import { makeRequest } from '../util/AxiosInstance';
import DisplayAlerts from '../shared/DisplayAlerts';
import { useBookingModal } from '../zustand';


const BookingTable = ({ title, status }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [hasMore, setHasMore] = useState(0)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { setOpen, setBooking, setId } = useBookingModal()


    const filteredBookings = data.filter(
        (booking) =>
            booking.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            booking.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const paginatedBookings = filteredBookings.slice(
        currentPage * rowsPerPage,
        currentPage * rowsPerPage + rowsPerPage
    );

    const fetchBooking = (status, page) => {
        setLoading(true)

        makeRequest('GET', `/bookings?status=${status}&page=${page}`).then((response) => {
            let data = response.data.data.data ?? []
            console.log(data);
            setData(data)
            // console.log(response.data)
        }).catch((error) => {
            let msg = handleError(error)
            setError(msg)
            console.log(error)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchBooking(status, page)
    }, [])

    return (
        <Box my={3} className="booking-table" padding={2}>
            <Grid container alignItems={'center'}>
                <Grid item>
                    <Typography component={'div'} variant="h6">{title}&nbsp;{loading && <CircularProgress size={20} />}</Typography>

                </Grid>
                <Grid item ml="auto">
                    <TextField
                        variant="standard"
                        placeholder='Search bookings'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px' }}
                        disabled={isEmpty(paginatedBookings)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton disabled={true} size='small'>
                                        <IoSearchOutline />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <DisplayAlerts apiErrors={error} />

            <>
                {!isEmpty(paginatedBookings) ? (<>
                    <table className='use-table'>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Start At</th>
                                <th>End At</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedBookings.length > 0 ? (
                                paginatedBookings.map((booking) => (
                                    <tr style={{
                                        textTransform: 'capitalize'
                                    }} key={booking.id}>
                                        <td>{booking.title}</td>
                                        <td>{booking.start_at}</td>
                                        <td>{booking.end_at}</td>
                                        <td><Button
                                            onClick={() => {
                                                setBooking(booking)
                                                setOpen(true)
                                                setId(booking.id)
                                            }}
                                            variant='text'>Manage</Button></td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">No bookings available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <TablePagination
                        component="div"
                        count={filteredBookings.length}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Show"
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                        }}
                    />
                </>) : <>
                    <Placeholder icon={Waiting} text="No bookings available" />
                </>}
            </>
        </Box>
    );
};

export default BookingTable;