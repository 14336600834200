import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react'
import { IoAddOutline } from "react-icons/io5";
import { useBookingStore } from '../zustand';
import { Link } from 'react-router-dom';
import BookingTable from '../components/BookingTable';
import BookingModal from '../components/BookingModal';


const BookingRenderer = () => {
  const { sortedBookings } = useBookingStore();
  const { expired, upcoming, unapproved } = sortedBookings;

  return (
    <div>
      <BookingTable bookings={unapproved} title="Unapproved" status={0} />
      <BookingTable bookings={upcoming} title="Upcoming" status={1} />
      <BookingTable bookings={expired} title="Expired" status={2} />
    </div>
  );
};


function Bookings() {
  return (
    <>
      <Box p={2}>
        <Grid container>
          <Grid item>
            <Typography fontWeight={600} variant="h5" gutterBottom>
              Bookings
            </Typography>
          </Grid>
          <Grid item ml="auto">
            <Button
              component={Link}
              to="/booking/edit"
              variant='contained'
              endIcon={<IoAddOutline />}
            >Create New</Button>
          </Grid>
        </Grid>
        <BookingRenderer />
        <BookingModal />
      </Box>
    </>
  )
}

export default Bookings
