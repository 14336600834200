import React, { useState, useEffect } from 'react';
import {
    Typography, Grid, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { makeRequest } from '../util/AxiosInstance';
import { API_URL, BACKEND_URL } from '../zustand/const';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import { isEmpty } from 'underscore';
import Placeholder from './Placeholder';
import { Block } from 'notiflix';

const SinglePortfolioView = ({ portfolioId, onNavigate, modalOpen, setModalOpen, currentItemNo, totalPortfolios }) => {
    const [portfolio, setPortfolio] = useState(null);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (modalOpen) {
            fetchPortfolioData();
        }
    }, [portfolioId, modalOpen]);

    const fetchPortfolioData = async () => {
        try {
            Block.circle('.port-content')
            const portfolioResponse = await makeRequest('GET', `${API_URL}/portfolio/${portfolioId}`);
            let data = portfolioResponse.data.data
            setPortfolio(data);

            if (data) {
                const fileIds = data.image_order
                const filesResponse = await makeRequest('GET', `${API_URL}/file?id=${fileIds.join(',')}`);
                const sortedFiles = sortFilesByType(filesResponse.data.data);
                setFiles(sortedFiles);
            }
        } catch (error) {
            console.error('Error fetching portfolio data:', error);
        } finally {
            Block.remove('.port-content')
        }
    };

    const sortFilesByType = (files) => {
        return files.sort((a, b) => {
            if (a.file_type.startsWith('video') && !b.file_type.startsWith('video')) return -1;
            if (!a.file_type.startsWith('video') && b.file_type.startsWith('video')) return 1;
            return 0;
        });
    };



    const handleCloseModal = () => {
        setModalOpen(false);
    };



    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={handleCloseModal}
                fullWidth

                maxWidth="md"
            >
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box gap={2} display="flex" justifyContent="space-between" alignItems="center">
                            <IconButton
                                sx={{
                                    border: '1px solid #f0f0f0'
                                }}
                                variant="outlined" onClick={() => onNavigate('prev')}>
                                <ArrowBack />
                            </IconButton>
                            <Typography variant="body2">Project {currentItemNo + 1} of {totalPortfolios}</Typography>
                            <IconButton
                                sx={{
                                    border: '1px solid #f0f0f0'
                                }}
                                variant="outlined " onClick={() => onNavigate('next')}>
                                <ArrowForward />
                            </IconButton>
                        </Box>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent
                    className='port-content'
                    sx={{
                        minHeight: {
                            xs: '80vh',
                            sm: '50vh',
                        },
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        borderRadius: '1rem'
                    }}
                    dividers>
                    {portfolio ? (
                        <>
                            <Typography textTransform="capitalize" variant="h4" my={2}>{portfolio?.name}</Typography>
                            <Typography textTransform="capitalize" variant="body1" paragraph mb={2}>{portfolio.description}</Typography>

                            <Grid container spacing={2} my={4}>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="body2" gutterBottom>Price Range</Typography>
                                    <Typography textTransform="capitalize" variant="subtitle1">${portfolio.price_start} - ${portfolio.price_end}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="body2" gutterBottom>Project Duration</Typography>
                                    <Typography textTransform="capitalize" variant="subtitle1">
                                        {new Date(portfolio.duration_start).toLocaleDateString()} -
                                        {new Date(portfolio.duration_end).toLocaleDateString()}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="body2" gutterBottom>Industry</Typography>
                                    <Typography textTransform="capitalize" variant="subtitle1">{portfolio.industry}</Typography>
                                </Grid>
                            </Grid>

                            <Box sx={{ mt: 2 }}>
                                <Typography variant="h5" mb={2}>Media</Typography>
                                <Grid container spacing={1}>
                                    {files && files.length > 0 && files.map((file, index) => (
                                        <Grid item key={file.id} xs={12}
                                            sx={{
                                                border: '1px solid #f0f0f0',
                                                borderRadius: '1rem',
                                                objectFit: 'cover',
                                                margin: '0.5rem auto'
                                            }}
                                        >
                                            {file.file_type.startsWith('video') ? (
                                                <video
                                                    width="100%"
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <source src={`${BACKEND_URL}${file.file_path}`} type={file.file_type} />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img

                                                    src={`${BACKEND_URL}${file.file_path}`}
                                                    alt={file.original_name}
                                                    style={{ width: '100%', cursor: 'pointer' }}
                                                />
                                            )}
                                        </Grid>
                                    ))}
                                </Grid>
                                {isEmpty(files) && <Placeholder text={'No media found'} />}
                            </Box>
                        </>
                    ) : (
                        <Typography>Loading...</Typography>
                    )}
                </DialogContent>

            </Dialog>
        </>
    );
};

export default SinglePortfolioView;