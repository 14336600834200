import { Box, Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { isEmpty } from 'underscore'
import { ReactComponent as Completed } from '../assets/completed.svg'
import { useProjectCardStore } from '../zustand'
import ProjectCardItem from './ProjectCardItem'

function ProjectCard() {
    const { projects, isLoading, fetchProjects } = useProjectCardStore()

    useEffect(() => {
        fetchProjects()
    }, [])

    return <>
        <Box
            component={motion.div}
            sx={{
                height: {
                    lg: '100%',
                    xs: 'auto'
                },
                minHeight: {
                    lg: '100%',
                    xs: '300px'
                }
            }}
            className="card-item projects-card">
            <div className="card-item-header">
                <Typography variant="h6">
                    Ongoing Projects
                </Typography>
                <Button disabled={isEmpty(projects)} color="secondary" variant="text">View All</Button>
            </div>
            {projects && !isEmpty(projects) ? projects.map((project, key) => (<>
                <ProjectCardItem project={project} key={key} />
            </>)) : <>
                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Completed style={{ width: '150px', height: '150px' }} />
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        No ongoing projects
                    </Typography>
                </Box>
            </>}
        </Box>
    </>
}

export default ProjectCard