import React, { useEffect } from 'react';
import { useTheme } from '@mui/material';
import { useChartStore } from '../zustand';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { isArray, isEmpty } from 'underscore';
import numeral from 'numeral';
import Placeholder from './Placeholder';
import { ReactComponent as Nodata } from '../assets/no_data.svg';
import { makeRequest } from '../util/AxiosInstance';
import { API_URL } from '../zustand/const';
import { formatDateForApi } from '../util/functions';

function ProjectChart({ currencyLabels, mode = 'line' }) {
    const theme = useTheme();
    const { projects, setProjects, chartData, setChartData, startDate, endDate } = useChartStore();

    const fetchProjects = () => {
        const params = new URLSearchParams();
        params.append('status', 2);
        params.append('page', 1);
        if (startDate) {
            params.append('start_date', formatDateForApi(startDate));
        }
        if (endDate) {
            params.append('end_date', formatDateForApi(endDate));
        }
        makeRequest(`GET`, `${API_URL}/projects?${params.toString()}`)
            .then(async (response) => {
                let data = response.data.data.data;
                setProjects(data);
            })
            .catch((error) => {
                console.error("Error fetching projects:", error);
            })
    }

    useEffect(() => {
        fetchProjects();
    }, [])

    useEffect(() => {
        fetchProjects();
    }, [startDate, endDate])

    useEffect(() => {
        if (isArray(projects) && projects.length > 0) {
            const data = projects
                .filter(project => +project.project_detail.status === 1)
                .reduce((acc, project) => {
                    const date = new Date(project.project_detail.start_at).toISOString().split('T')[0];
                    const amount = parseFloat(project.project_detail.amount);
                    acc[date] = (acc[date] || 0) + amount;
                    return acc;
                }, {});

            const dd = [];
            console.log(data);
            for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                const date = d.toISOString().split('T')[0];
                dd.push({
                    date: d.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' }),
                    revenue: data[date] || 0,
                });
            }
            console.log(dd);
            setChartData(dd);
        }
    }, [projects, startDate, endDate]);

    const formatNumber = (value) => numeral(value).format('0.[0]a');

    return (
        <>
            {chartData && !isEmpty(chartData) &&
                <ResponsiveContainer width="100%" height={400}>
                    {mode === 'line' ? (
                        <LineChart
                            data={chartData}
                            margin={{
                                right: 20,
                                left: -10
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
                            <XAxis dataKey="date" tick={{ fill: theme.palette.text.primary, fontSize: 12 }} />
                            <YAxis tick={{ fill: theme.palette.text.primary, fontSize: 12 }} tickFormatter={formatNumber} />
                            <Tooltip contentStyle={{ backgroundColor: theme.palette.background.paper, borderColor: theme.palette.divider }} formatter={formatNumber} />
                            <Legend verticalAlign="top" height={36} />
                            <Line type="monotone" dataKey="revenue" stroke={theme.palette.secondary.main} name={currencyLabels.currency} dot={false} />
                        </LineChart>
                    ) : (
                        <BarChart
                            data={chartData}
                            margin={{
                                right: 20
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
                            <XAxis dataKey="date" tick={{ fill: theme.palette.text.primary, fontSize: 14 }} />
                            <YAxis tick={{ fill: theme.palette.text.primary, fontSize: 14 }} tickFormatter={formatNumber} />
                            <Tooltip contentStyle={{ backgroundColor: theme.palette.background.paper, borderColor: theme.palette.divider }} formatter={formatNumber} />
                            <Legend verticalAlign="top" height={36} />
                            <Bar dataKey="revenue" fill={theme.palette.secondary.main} name={currencyLabels.currency.toUpperCase()} />
                        </BarChart>
                    )}
                </ResponsiveContainer>
            }
            {isEmpty(chartData) && <Placeholder icon={Nodata} text={'No data to display'} />}

        </>
    );
}

export default ProjectChart;