import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Button, Chip, Divider, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import { LuCalendarPlus } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { motion } from "framer-motion";
import { formatDateFromApi } from "../util/functions";
import { PiCalendarStarThin } from "react-icons/pi";
import { TbCalendarTime } from "react-icons/tb";

function ProjectItem({ project }) {
  const navigate = useNavigate();

  console.log(project);

  const handleNavigate = (project) => {
    navigate(`/project/${project.id}`);
  }

  const calculateDaysLeft = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  return (
    <motion.div className="project-item" onClick={() => handleNavigate(project)}>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography variant="body1" fontWeight={"500"} mb={1}>
              {project.name ?? `Project ${project.id ?? "#"}`}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "start",
          }}>
            <IconButton size="small">
              <BsThreeDotsVertical />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="body2">
          {project.description
            ? project.description.length > 100
              ? `${project.description.substring(0, 97)}...`
              : project.description
            : ""}
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Chip icon={<TbCalendarTime size={16} />} label={formatDateFromApi(project.project_detail.end_at)} />
      </div>
      <Divider />
      <Grid container spacing={2}
        style={{ marginTop: "1rem" }}>
        <Grid item xs={6}>
          <Typography variant="caption">{calculateDaysLeft(project.project_detail.start_at, project.project_detail.end_at)} days left</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "0.3rem",
          }}>
            <span style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}><LuCalendarPlus />
            </span>
            <span>
              {new Date(project.project_detail.start_at).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </motion.div>
  );
}

function ProjectItemSkeleton() {
  return (
    <Box
      className="project-item"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        mb: 2,
        borderRadius: '1rem',
        bgcolor: 'background.paper',
      }}>
      <Skeleton variant="text" width="60%" height={16} />
      <Skeleton variant="text" width="40%" height={32} sx={{ mt: 1 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Skeleton variant="rectangular" width={100} height={20} />
        <Skeleton variant="rectangular" width={100} height={20} />
      </Box>
    </Box>
  );
}

export { ProjectItemSkeleton };
export default ProjectItem;
