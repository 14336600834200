import { Avatar, Typography, Box, useMediaQuery, useTheme, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { userStore } from '../zustand';
import { CgProfile } from "react-icons/cg";
import { isEmpty } from 'underscore';

function ProfileTab() {
    const theme = useTheme();
    const { user } = userStore()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                ml: "auto",
                alignItems: "center",
                display: "flex",
                gap: "10px",
            }}
        >
            <Button
                variant='text'
                startIcon={<CgProfile style={{ color: '#444' }} />}
            >
                {!isMobile &&
                    <Typography
                        textTransform={'capitalize'}
                        variant="body1"
                        fontWeight={"500"}
                        fontSize={'1.1rem'}>
                        {!isEmpty(user) && user.hasOwnProperty('first_name') ? `${user.first_name} ${user.last_name}` : 'Guest'}
                    </Typography>
                }
            </Button>

        </Box>
    )
}

export default ProfileTab