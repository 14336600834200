import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { Box } from "@mui/system";
import Dashboard from "./wrappers/DashboardWrapper";
import { analyticsStore, useBookingStore, useProjectStore, useTimeZoneStore } from "./zustand";

function App() {
  const { fetchProjects, loadStatus } = useProjectStore();
  const { fetchTimezones } = useTimeZoneStore()
  const { getAnalytics } = analyticsStore()
  const { fetchBookings } = useBookingStore()

  useEffect(() => {
    fetchProjects(1, 1);
    loadStatus()
    fetchTimezones()
    getAnalytics()
    fetchBookings(1)
  }, []);

  return (
    <Dashboard>
      <Box component={motion.main}>
        <Outlet />
      </Box>
    </Dashboard>
  );
}
export default App;