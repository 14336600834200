import React, { useState, useEffect } from 'react';
import {
    List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction,
    IconButton, Menu, MenuItem, Button, Typography, Box,
    Skeleton
} from '@mui/material';
import { DragHandle, MoreVert, Visibility } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeRequest } from '../util/AxiosInstance';
import { API_URL } from '../zustand/const';
import { LoadingButton } from '@mui/lab';
import { RiDraggable } from "react-icons/ri";
import { Confirm, Notify } from 'notiflix';
import PortfolioViewer from './PortfolioView';
import { isEmpty } from 'underscore';
import { useNavigate } from 'react-router-dom';
import { usePortfolioStore } from '../zustand';

const PortfolioList = () => {
    const { portfolios, setPortfolios, currentPage, setCurrentPage, totalItems, addPortfolio, setTotalItems, hasMore, setHasMore, increasePage } = usePortfolioStore();

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuPortfolio, setMenuPortfolio] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showViewer, setShowViewer] = useState(false);
    const [viewerId, setViewerId] = useState(null);

    const navigate = useNavigate();

    const fetchPortfolios = async () => {
        if (isLoading) return;
        try {
            setIsLoading(true);
            const response = await makeRequest('GET', `${API_URL}/portfolio?page=${currentPage}&per_page=10`);
            const { data, total, current_page, last_page } = response.data.data;
            let toAdd = data.filter(item => !portfolios.some(p => p.id === item.id));
            addPortfolio(toAdd);
            setTotalItems(total);
            setHasMore(current_page < last_page);
        } catch (error) {
            console.error('Error fetching portfolios:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!isLoading) {
            fetchPortfolios();
        }
    }, [currentPage]);

    const handleLoadMore = () => {
        increasePage();
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;
        const items = Array.from(portfolios);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setPortfolios(items);


        const startIndex = Math.min(result.source.index, result.destination.index);
        const endIndex = Math.max(result.source.index, result.destination.index);
        const updatedOrders = items
            .slice(startIndex, endIndex + 1)
            .map((project, index) => ({
                id: project.id,
                order: startIndex + index + 1
            }));
        try {
            await makeRequest('POST', `${API_URL}/portfolio/reorder`, updatedOrders).then(() => {
                Notify.success('Portfolio reordered successfully');
            }).catch((error) => {
                Notify.failure('Failed to reorder portfolio');
            });
        } catch (error) {
            console.error('Error updating order:', error);
        }
    };

    const handleMenuOpen = (event, portfolio) => {
        setAnchorEl(event.currentTarget);
        setMenuPortfolio(portfolio);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuPortfolio(null);
    };

    const handleEdit = () => {
        navigate(`/portfolio/edit/${menuPortfolio.id}/`);
    };

    const handleDelete = async () => {
        try {
            Confirm.show(
                'Are you sure?',
                'This action cannot be undone.',
                'Delete',
                'Cancel',
                async () => {
                    makeRequest('DELETE', `${API_URL}/portfolio/${menuPortfolio.id}`).then(() => {
                        setPortfolios(portfolios.filter(p => p.id !== menuPortfolio.id));
                        Notify.success('Project deleted successfully');
                    }).catch((error) => {
                        Notify.failure('Failed to delete project');
                    });
                },
                () => {
                    console.log('Canceled');
                }
            );
        } catch (error) {
            console.error('Error deleting portfolio:', error);
        }
        handleMenuClose();
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Total ({totalItems})
            </Typography>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="portfolios">
                    {(provided) => (
                        <List {...provided.droppableProps} ref={provided.innerRef}>
                            {portfolios && !isEmpty(portfolios) && portfolios.length > 0 && portfolios.map((portfolio, index) => (
                                <Draggable key={portfolio.id} draggableId={portfolio.id.toString()} index={index}>
                                    {(provided) => (
                                        <ListItem
                                            key={portfolio.id}
                                            className='portfolio-item'
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <ListItemIcon className='drag-handle'>
                                                <RiDraggable />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={portfolio.name}
                                                secondary={`Category: ${portfolio.category}`}
                                            />
                                            <ListItemSecondaryAction>
                                                <Button onClick={() => { setViewerId(portfolio.id); setShowViewer(true); }} variant="text" color='secondary'>
                                                    View
                                                </Button>
                                                <IconButton edge="end" onClick={(e) => handleMenuOpen(e, portfolio)}>
                                                    <MoreVert />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {isEmpty(portfolios) && isLoading && <>
                                <Skeleton variant="rectangular" height={60} sx={{
                                    margin: '1rem 0',
                                    borderRadius: '1rem'
                                }} />
                                <Skeleton variant="rectangular" height={60} sx={{
                                    margin: '1rem 0',
                                    borderRadius: '1rem'
                                }} />
                                <Skeleton variant="rectangular" height={60} sx={{
                                    margin: '1rem 0',
                                    borderRadius: '1rem'
                                }} />
                            </>}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

            {hasMore && (
                <LoadingButton variant="text" loading={isLoading} onClick={handleLoadMore}
                    sx={{ display: 'block', m: '1rem auto' }}>
                    Load More
                </LoadingButton>

            )}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>

            {showViewer && <PortfolioViewer portfolios={portfolios} id={viewerId}
                setId={setViewerId}
                modalOpen={showViewer} setModalOpen={setShowViewer}
            />}
        </Box>
    );
};

export default PortfolioList;