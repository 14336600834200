import { Typography } from "@mui/material"

export const TitlePlusText = ({ title, text }) => {
    return (<>
        <Typography variant="caption" className='useLightColor' textTransform={'uppercase'}>
            {title}
        </Typography>
        <Typography fontWeight={400} variant="body1" gutterBottom>
            {text}
        </Typography>
    </>
    )
}