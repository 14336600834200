import React, { useState, useEffect } from 'react';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { Download, InsertDriveFile } from '@mui/icons-material';
import { Box } from '@mui/system';
import { IoCloudDownloadOutline } from "react-icons/io5";
import { makeRequest } from '../util/AxiosInstance';
import FileDownload from 'js-file-download';
import { LoadingButton } from '@mui/lab';

const FileMessage = ({ message, direction, url }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const [downloadProgress, setDownloadProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState(null);


    const isImage = message.file && message.file.type.startsWith('image/');
    const isVideo = message.file && message.file.type.startsWith('video/');

    useEffect(() => {
        if (message.toBeUploaded && message.file) {
        }
        if (message.content) {
            setDownloadUrl(message.content);
            if (isImage) {
                setPreviewUrl(message.content);
            }
        }
    }, [message]);

    const handleDownload = async () => {
        if (downloadUrl) {
            setIsDownloading(true);
            setDownloadError(null);
            try {
                const response = await makeRequest('GET', downloadUrl, null, {
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setDownloadProgress(percentCompleted);
                    },
                });

                const contentType = response.headers['content-type'];
                const contentDisposition = response.headers['content-disposition'];
                let fileName = message.name || 'download';

                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/i);
                    if (fileNameMatch && fileNameMatch[1]) {
                        fileName = fileNameMatch[1];
                    }
                }

                const blob = new Blob([response.data], { type: contentType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Download error:', error);
                setDownloadError('An error occurred during download. Please try again.');
            } finally {
                setIsDownloading(false);
                setDownloadProgress(0);
            }
        }
    };

    return (

        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '10px',
            }}>
            {message.toBeUploaded && uploadProgress < 100 ? (
                <>
                    <CircularProgress variant="determinate" value={uploadProgress} />
                    <Typography variant="body2">{`Uploading... ${uploadProgress}%`}</Typography>
                </>
            ) : (
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#fff',
                        height: '90px',
                        width: '100%',
                        borderBottom: '1px solid #f1f1f1',
                    }}>
                        {isImage && previewUrl ? (
                            <img src={previewUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                        ) : (
                            <InsertDriveFile fontSize="large" />
                        )}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '10px',
                        padding: '10px',
                        width: '100%',
                    }}>
                        <Typography variant="body2">{message.name ? message.name : 'File'}</Typography>
                        <LoadingButton
                            variant="text"
                            color='secondary'

                            size="small"
                            sx={{
                                borderRadius: '50%',
                                padding: '1px',
                                fontWeight: 'bold',
                                width: '20px',
                                height: '20px',
                            }}
                            loading={isDownloading}
                            onClick={handleDownload}
                            disabled={!downloadUrl}
                        >
                            <IoCloudDownloadOutline />
                        </LoadingButton>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default FileMessage;