import React, { useState, useEffect } from "react";
import { IconButton, Typography, Box } from "@mui/material";
import { useTimeZoneStore } from "../zustand";
import { LuCalendarClock } from "react-icons/lu";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { CiEdit } from "react-icons/ci";

function BookingItem({ booking }) {
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const { getTzById } = useTimeZoneStore();
  const navigate = useNavigate();

  useEffect(() => {
    let tz = getTzById(booking.start_at_time_zone_id);
    let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let localStartTime = moment.tz(booking.start_at, tz).tz(userTimeZone);
    let localEndTime = moment.tz(booking.end_at, tz).tz(userTimeZone);
    let meetingDate = localStartTime.format("dddd, MMMM D");
    let startTime = localStartTime.format("HH:mm A");
    let endTime = localEndTime.format("HH:mm A");

    setDate(meetingDate);
    setStartTime(startTime);
    setEndTime(endTime);
  }, [booking]);

  return (
    <div className="booking-item">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <Typography textTransform={"capitalize"} variant="body1" fontWeight={"semibold"}>
            {booking.title}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "baseline", gap: "0.5rem" }}>
            <span><LuCalendarClock fontSize={'12px'} /></span>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25rem" }}>
              <Typography variant="body2" fontWeight={400}>
                {startTime} - {endTime}
              </Typography>
              <Typography variant="caption" fontWeight={300}>
                {date}
              </Typography>
            </Box>
          </Box>
        </Box>
        <div>
          <IconButton size="small" onClick={() => {
            navigate(`/booking/edit/${booking.id}`);
          }}>
            <CiEdit />
          </IconButton>
        </div>
      </div>
    </div>
  );
}



export default BookingItem;